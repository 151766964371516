import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { DashboardPage } from "./pages/DashboardPage";

import { OrganizationPage } from "./pages/OrganizationPage";
import { TraversHistoryPage } from "./pages/TraversHistoryPage";
import { UserDetailsPage } from "./pages/UserDetailsPage";
import { HistoryDetailsPage } from "./pages/History";
import { HistoryTraversalTableWidget } from "../_metronic/_partials/widgets/advance-tables/HistoryTraversalTableWidget";

import { Toolbox } from "./pages/Toolbox";
import { ToolboxDetailsPage } from "./pages/ToolBoxDetailPage";
import { TraversalPage } from "./pages/TraversalPage";
import { ViewReportPage } from "./pages/ViewReportPage";
import { useHistory } from "react-router-dom";
import { ToolboxLanding,ToolboxCategory,ToolboxSolutionPage } from "../_metronic/_partials";


const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const history = useHistory();
  const handleClose = () => {
    history.replace("/organization");
  };
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute exact path="/dashboard" component={ToolboxLanding} />
        <ContentRoute
          exact
          path="/toolbox"
          component={ToolboxCategory}
        />
        <ContentRoute
          exact
          path="/toolbox/solution/:subToolBoxId"
          component={ToolboxSolutionPage}
        />
        <ContentRoute exact path="/history" component={HistoryDetailsPage} />
        {/* {<Redirect exact from="/" to="/dashboard" />} */}
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}

        <ContentRoute exact path="/organization" component={OrganizationPage} />
        {/* <ContentRoute path="/question/:id" component={TopicQuestionPage} /> */}

        <ContentRoute path="/users/:id" component={UserDetailsPage} />
        <ContentRoute exact path="/toolbox-design" component={Toolbox} />
       <ContentRoute
          exact
          path="/toolbox/:id"
          component={ToolboxDetailsPage}
        /> 

        <ContentRoute
          exact
          path="/history/questionDetail/:question/:id"
          component={HistoryTraversalTableWidget}
        />
        <ContentRoute path="/history/:id" component={TraversHistoryPage} />
        {/* <ContentRoute
          path="/organization/edit/:id"
          component={() => <IntechChatBot onClose={handleClose} />}
        /> */}

        <Route path="/user-profile" component={UserProfilepage} />

        <ContentRoute exact path="/traversal/:id" component={TraversalPage} />

        <ContentRoute
          exact
          path="/viewReport/:transactionId"
          component={ViewReportPage}
        />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
