import { uniqueId } from "lodash";
import { question } from "../questions/questions.service";
import { topic } from "../topics/topic.service";
import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const addSubtopic = (subtopicName, parentTopic) => {
  return Services({
    url: "/admin/subTopic/",
    method: "POST",
    data: {
      name: subtopicName,
      parentTopic: parentTopic,
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const updateSubtopic = (id, subtopicName) => {
  return Services({
    url: `/admin/subTopic/${id}`,
    method: "PUT",
    data: {
      name: subtopicName,
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const deleteSubtopic = (id) => {
  return Services({
    url: `/admin/subTopic/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getTopicDetails = (id) => {
  let url = '/admin/topic/details'
  if(id != '' && id != undefined){
    url = url + '?topicId=' + id
  }
  return Services({
    url: url,
    method: "GET",
  });
};

let subtopics = [
  {
    _id: "62a8243346e2bc3c641f8f6d",
    name: "Email automation Software",
    updatedAt: "2022-06-14T06:01:23.708Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    questionCount: 2,
    parentTopic: "62a80effe2b34a44a0b9f192",
  },
  {
    _id: "62a8245146e2bc3c641f8f71",
    name: "Chatbot software",
    updatedAt: "2022-06-14T06:01:53.918Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    questionCount: 1,
    parentTopic: "62a80effe2b34a44a0b9f192",
  },
  {
    _id: "abc",
    name: "Analytics tools",
    updatedAt: "2022-06-14T06:01:23.708Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    questionCount: 0,
    parentTopic: "62a80f64e2b34a44a0b9f196",
  },
  {
    _id: "pqr",
    name: "instance 2",
    updatedAt: "2022-06-14T06:01:53.918Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    questionCount: 0,
    parentTopic: "62a80f64e2b34a44a0b9f196",
  },
  {
    _id: "def",
    name: "instance 3",
    updatedAt: "2022-06-14T06:01:23.708Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    questionCount: 0,
    parentTopic: "62a80f64e2b34a44a0b9f196",
  },
  {
    _id: "xyz",
    name: "instance 4",
    updatedAt: "2022-06-14T06:01:53.918Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    questionCount: 0,
    parentTopic: "62a837348fefd2471c1bdf70",
  },
];

export const subtopic = { subtopics };

export const _addSubtopic = (subtopicName, parentTopic) => {
  const date = new Date().toGMTString();
  subtopic.subtopics.push({
    _id: uniqueId(),
    name: subtopicName,
    updatedAt: date,
    createdAt: date,
    questionCount: 0,
    parentTopic: parentTopic,
  });
  topic.topics = topic.topics.map((topic) => {
    if (topic._id === parentTopic) {
      topic.subTopicsCount += 1;
    }
    return topic;
  });

  const promise = new Promise((res) => {
    res({
      data: { name: subtopic, parentTopic: parentTopic },
    });
  });

  return promise;
};

export const _updateTopic = (id, subtopicName) => {
  const date = new Date().toGMTString();
  subtopic.subtopics = subtopic.subtopics.map((subtopicObj) => {
    if (subtopicObj._id === id) {
      subtopicObj.name = subtopicName;
      subtopicObj.updatedAt = date;
    }
    return subtopicObj;
  });

  const promise = new Promise((res) => {
    res({
      data: { name: subtopicName },
    });
  });

  return promise;
};

export const _deleteSubTopic = (id, parentTopic) => {
  subtopic.subtopics = subtopic.subtopics.filter(
    (subtopicObj) => subtopicObj._id !== id
  );

  topic.topics = topic.topics.map((topic) => {
    if (topic._id === parentTopic) {
      topic.subTopicsCount -= 1;
    }
    return topic;
  });

  const promise = new Promise((res) => {
    res({
      data: {
        code: 200,
        message: "SubTopic deleted successfully!",
        data: [],
        error: [],
      },
    });
  });

  return promise;
};

export const _getSubtopicDetails = (id) => {
  const questions = question.questions.filter(
    (question) => question.parentSubtopic === id
  );
  const subtopicDetails = subtopic?.subtopics?.find(
    (subtopicObj) => subtopicObj._id === id
  );

  const promise = new Promise((res) => {
    res({
      data: {
        code: 200,
        message: "Topic Get successfully!",
        data: [
          {
            ...subtopicDetails,
            questions: questions,
          },
        ],
        error: [],
      },
    });
  });

  return promise;
};
