import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThankyouDialog } from "../../modals/thankyou-dialog/ThankyouDialog";
import "../Traversal.scss";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";

export function EndComponent({
  nodeData,
  transactionId,
  questionId,
  questionDetails,
}) {
  const dispatch = useDispatch();
  let history = useHistory();
  const nodeIsLoading = useSelector((store) => store.webTravers.nodeIsLoading);

  const handleClose = () => {
    let data = {
      fromLink: nodeData.fromLink,
      toNode: nodeData._id,
      transactionId: transactionId,
      questionId: questionId,
    };
    dispatch(WEB_TRAVERS.endNodeDataApi(data));
    dispatch(WEB_TRAVERS.resetToInitialState());
    history.push({
      pathname: `/toolbox/solution/${questionDetails.topicId}`,
    });
  };

  return (
    <>
      <div className="card-body">
        <h3 className="card-title flex-column">
          <span className="card-label font-weight-bolder text-dark"></span>
        </h3>
      </div>
      <div className="card-footer"></div>
      <ThankyouDialog handleClose={() => handleClose()} />
    </>
  );
}
