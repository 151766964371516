import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import * as TOPIC_SERVICES from "../../../../_service/topics/topic.service";

export const actionTypes = {
  ADD: "[TOPICS_ADD] Action",
  UPDATE: "[TOPICS_UPDATE] Action",
  DELETE: "[TOPICS_DELETE] Action",
  LIST: "[TOPICS_LIST] Action",
  LOADING: "[TOPICS_LOADING] Action",
  ERROR: "[TOPICS_ERROR] Action",
  GET_TOPIC_LIST: "[GET_TOPIC_LIST] Action",
};

const initialState = {
  topics: [],
  isLoading: false,
  error: "",
};

export const reducer = persistReducer(
  { storage, key: "topic" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST: {
        const { topics } = action.payload;

        return { ...state, topics };
      }

      case actionTypes.LOADING: {
        const { isLoading } = action.payload;

        return { ...state, isLoading };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;

        return { ...state, error };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  add: (topic) => ({ type: actionTypes.ADD, payload: { topic } }),
  update: (id, topic) => ({ type: actionTypes.UPDATE, payload: { id, topic } }),
  delete: (id) => ({ type: actionTypes.DELETE, payload: { id } }),
  list: (topics) => ({ type: actionTypes.LIST, payload: { topics } }),
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING,
    payload: { isLoading },
  }),
  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),
  getTopicList: () => ({ type: actionTypes.GET_TOPIC_LIST }),
};

export function* saga() {
  yield takeLatest(actionTypes.ADD, function* addSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { topic } = action.payload;
      yield call(TOPIC_SERVICES.addTopic, topic);
      const response = yield call(TOPIC_SERVICES.getTopic);
      yield put(actions.list(response.data?.data || initialState.topics));
    } catch (error) {
      yield put(actions.error("Failed topic add!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.UPDATE, function* updateSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { id, topic } = action.payload;
      yield call(TOPIC_SERVICES.updateTopic, id, topic);
      const response = yield call(TOPIC_SERVICES.getTopic);
      yield put(actions.list(response.data?.data || initialState.topics));
    } catch (error) {
      yield put(actions.error("Failed topic update!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.DELETE, function* deleteSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { id } = action.payload;
      yield call(TOPIC_SERVICES.deleteTopic, id);
      const response = yield call(TOPIC_SERVICES.getTopic);
      yield put(actions.list(response.data?.data || initialState.topics));
    } catch (error) {
      yield put(actions.error("Failed topic delete!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.GET_TOPIC_LIST, function* listSaga() {
    yield put(actions.isLoading(true));
    try {
      const response = yield call(TOPIC_SERVICES.getTopic);
      yield put(actions.list(response.data?.data || initialState.topics));
    } catch (error) {
      yield put(actions.error("Failed topic fetch!"));
    }
    yield put(actions.isLoading(false));
  });
}
