import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";
import { organizations } from "./_mockOrganizations";

const updateOrganization = (organization) => {
  const {
    _id,
    createdBy,
    updatedBy,
    updatedAt,
    createdAt,
    ...data
  } = organization;
  // const url = getUrl("organization") + "/" + id;
  // return put(url, rest, getUserToken());
  return Services({
    url: `/admin/organization/${_id}`,
    method: "PUT",
    data: data,
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getOrganizations = () => {
  // const url = getUrl("organization");
  // return get(url, getUserToken());
  return Services({
    url: `/admin/organization`,
    method: "GET",
  });
  // return new Promise((res) => res(organizations));
};

export const getOrganization = () => {

  return Services({
    url: `/user/profile/organization-details`,
    method: "GET",
  });

};
export const _updateOrganization = (organization) => {
  const promise = new Promise(async (res) => {
    let response = await updateOrganization(organization);
    res(response);
  });

  return promise;
};

// export const _getOrganizations = () => {
//   const promise = new Promise(async (res) => {
//     let response = await getOrganizations();
//     res(response);
//   });

//   return promise;
// };
