import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";

import * as HELP_SECTION_SERVICES from "../../../../_service/helpSection/helpSection.service";

export const actionTypes = {
  LOADING: "[PAGE_DATA_LOADING] Action",
  ERROR: "[PAGE_DATA_ERROR] Action",
  GET_SINGLE_PAGE_DATA: "[GET_SINGLE_PAGE_DATA] Action",
  SET_SINGLE_PAGE_DATA: "[SET_SINGLE_PAGE_DATA] Action",
};

const initialHelpSectionState = {
  isLoading: false,
  error: "",
  singlePageDetails: [],
};

export const reducer = persistReducer(
  { storage, key: "helpSection" },
  (state = initialHelpSectionState, action) => {
    switch (action.type) {
      case actionTypes.LOADING: {
        const { isLoading } = action.payload;

        return { ...state, isLoading };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;

        return { ...state, error };
      }

      case actionTypes.SET_SINGLE_PAGE_DATA: {
        const { pagedata } = action.payload;

        return { ...state, singlePageDetails: pagedata };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING,
    payload: { isLoading },
  }),

  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),

  getsinglePageData: (pageName, isToolBox) => ({
    type: actionTypes.GET_SINGLE_PAGE_DATA,
    payload: { pageName, isToolBox },
  }),
  setsinglePageData: (pagedata) => ({
    type: actionTypes.SET_SINGLE_PAGE_DATA,
    payload: { pagedata },
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.GET_SINGLE_PAGE_DATA,
    function* getsinglePageDataSaga(action) {
      yield put(actions.isLoading(true));
      try {
        const { pageName, isToolBox } = action.payload;
        const response = yield call(
          HELP_SECTION_SERVICES.getSinglePageData,
          pageName,
          isToolBox
        );
        yield put(
          actions.setsinglePageData(
            response.data?.data || initialHelpSectionState.singlePageDetails
          )
        );
        yield put(actions.error(""));
      } catch (error) {
        console.log(error);
        yield put(actions.error("Failed Page Name fetch!"));
      }
      yield put(actions.isLoading(false));
    }
  );
}
