import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import "../Traversal.scss";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import { traversalSectionHeight } from "../../../_utils/constants";

export function MannualInput({ nodeData, transactionId, questionId }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [valueArray, setValueArray] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const retraversalData = useSelector(
    (store) => store.webTravers.retraversalData
  );
  useEffect(() => {
    if (nodeData?.metaData?.inputs.length > 0) {
      let valueNew = [];
      let data = nodeData?.metaData?.inputs?.map((item, index) => {
        let variableName =
          item.variableName !== "" ? item.variableName : item.localVariableName;
        let variableLabel = item.localVariableName;
        let selectedData = retraversalData.find(
          (data) => Object.keys(data)[0] == variableLabel
        );
        let valueData = "";

        if (selectedData !== undefined) {
          valueData = selectedData[variableLabel];
        }
        if (item.inputType == "Date" && valueData == "") {
          let date = new Date();
          let day = date.getDate();
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          valueData = day + "/" + month + "/" + year;
        }
        let dataObject = {
          variableName: variableName,
          value: valueData,
          variableId: item.variableId,
          constraints: item.constraints,
        };
        Object.freeze(dataObject);
        valueNew.push(dataObject);
      });

      setValueArray(valueNew);
    }
  }, [nodeData]);

  const handleConfirm = () => {
    let errorData = valueArray.filter(
      (item) => item.value == "" && item.constraints == "not_null"
    );

    if (errorData.length > 0) {
      setErrorArray(
        errorData.map((item) => {
          return item.variableName;
        })
      );
    } else {
      setErrorArray([]);
      let data = {
        fromLink: nodeData.fromLink,
        toNode: nodeData._id,
        transactionId: transactionId,
        nextNodeLinkId: nodeData.links[0]._id,
        questionId: questionId,
        metaData: {
          inputs: valueArray,
        },
      };
      dispatch(WEB_TRAVERS.saveNodeDataApi(data));
    }
  };
  const handleInputChange = (value, variableName, id, date = "") => {
    let inputValue = [...valueArray];
    let enterValue = value;
    let index = inputValue.findIndex(
      (item) => item.variableName == variableName
    );
    let dataObject = {
      variableName: variableName,
      variableId: id,
    };
    if (date != "") {
      dataObject.timeStamp = date;
    }
    Object.freeze(inputValue[index]);
    if (index !== -1) {
      dataObject.value = enterValue;
      inputValue[index] = dataObject;
    } else {
      dataObject.value = enterValue;
      inputValue.push(dataObject);
    }

    setValueArray(inputValue);
  };
  const [showContinueButtonAbove, setShowContinueButtonAbove] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref != null) {
      setShowContinueButtonAbove(
        ref.current.clientHeight > traversalSectionHeight
      );
    }
  });
  
  

  return (
    <>
      <div className="card-body" ref={ref}>
        <div className="card-header">
          <h3 className="card-title flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {nodeData?.metaData?.heading}
            </span>
          </h3>
          {showContinueButtonAbove && (
            <TraversalFooter nextNode={() => handleConfirm()  } />
          )}
        </div>
        {nodeData?.metaData?.inputs?.length > 0 &&
          nodeData?.metaData?.inputs?.map((item, index) => {
            let variableName =
              item.variableName !== ""
                ? item.variableName
                : item.localVariableName;
            let findIndex = valueArray.findIndex(
              (values) => values.variableName == variableName
            );
            let InputValue = valueArray[findIndex]?.value;
            if (item.inputType === "Date" && InputValue != undefined) {
              let dateArray = InputValue.split("/");

              if (dateArray.length > 0 && InputValue != "") {
                InputValue = new Date(
                  parseInt(dateArray[2]),
                  parseInt(dateArray[1]) - 1,
                  parseInt(dateArray[0])
                );
              } else {
                InputValue = new Date();
              }
            }
            return (
              <>
                <div
                  key={"_" + variableName}
                  className={`input-block ${
                    nodeData?.metaData?.isGridLayout ? "grid-manual-input" : ""
                  }`}
                >
                  <h3 className="input-title mb-5">
                    <span className="font-weight-bolder text-dark">
                      {item.label}
                    </span>
                  </h3>
                  <div className="form-group manual-input d-flex align-items-center">
                    {item.inputType === "Date" ? (
                      <>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholder={item.label}
                          className="form-control form-control-solid h-auto py-5 px-6"
                          showYearDropdown
                          showMonthDropdown
                          selected={InputValue}
                          onChange={(date) => {
                            let day = date.getDate();
                            let year = date.getFullYear();
                            let month = date.getMonth() + 1;
                            handleInputChange(
                              day + "/" + month + "/" + year,
                              variableName,
                              item.variableId,
                              date
                            );
                          }}
                        />
                      </>
                    ) : (
                      <input
                        key={index + "_" + variableName}
                        type={item.inputType === "Number" ? "number" : "text"}
                        className="form-control form-control-solid h-auto py-5 px-6"
                        placeholder={item.label}
                        value={InputValue}
                        onChange={(event) => {
                          if (item.inputType === "String") {
                            handleInputChange(
                              event.target.value,
                              variableName,
                              item.variableId
                            );
                          }
                          if (item.inputType === "Number") {
                            if (event.target.value >= 0) {
                              handleInputChange(
                                event.target.value,
                                variableName,
                                item.variableId
                              );
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                {errorArray.findIndex((error) => error == variableName) >
                  -1 && <span className="text-danger">Please add input</span>}
              </>
            );
          })}

        {nodeData?.metaData?.inputs == undefined && (
          <div className="input-block">
            <h3 className="input-title mb-5">
              <span className="font-weight-bolder text-dark">
                {nodeData.metaData.label}
              </span>
            </h3>
            <div className="form-group per__form__group d-flex align-items-center">
              <input
                type="text"
                className="form-control form-control-solid h-auto py-5 px-6"
                placeholder={nodeData.metaData.label}
                value={value}
                onChange={(event) => setValue(event.target.value)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="card-footer">
        <TraversalFooter nextNode={() => handleConfirm()} />
      </div>
    </>
  );
}
