import React, { useState, useEffect } from "react";
import { uniq } from "lodash";
import { RingLoader } from "react-spinners";
import { Services } from "../../../../_service/Services";

function MultiOption({
  conditionNodeDetails,
  columnName,
  groupByColumn,
  linkLabel,
  selectedOptionFromConditionalNode,
  className,
  isSingle,
  onFieldChange,
  reTraverseData,
  optionNodeType,
}) {
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    if (selectedValue !== undefined) {
      onFieldChange(selectedValue);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (linkLabel.toLowerCase() === "no" || optionNodeType == "MultiOption") {
      const values = conditionNodeDetails?.filter(
        (prd) => prd?.isSelected === true
      );

      let selecterValueIds = [];
      values.map((item) => {
        selecterValueIds.push(item._id);
      });

      setSelectedValue(selecterValueIds);
    } else {
      let selectedIds = [];
      conditionNodeDetails.map((item) => {
        selectedIds.push(item._id);
      });
      setSelectedValue(selectedIds);
    }
  }, [linkLabel]);

  useEffect(() => {
    // let selectedData = retraversalData.find((item) => Object.keys(item)[0] == nodeData?.metaData?.localVariableName)

    if (reTraverseData != undefined && reTraverseData.length > 0) {
      let selectedIds = [];
      reTraverseData.map((item) => {
        selectedIds.push(item._id);
      });
      setSelectedValue(selectedIds);
    }
  }, [reTraverseData]);
  const handleValue = (event) => {
    let oldSelectedValue = [...selectedValue];
    if (isSingle) {
      if (event.target.checked) {
        oldSelectedValue = [event.target.value];
      } else {
        oldSelectedValue = [];
      }
    } else {
      if (event.target.checked) {
        oldSelectedValue.push(event.target.value);
      } else {
        let index = oldSelectedValue.indexOf(event.target.value);
        if (index > -1) {
          oldSelectedValue.splice(index, 1);
        }
      }
    }
    setSelectedValue(oldSelectedValue);
  };

  return (
    <div className="checkbox-block">
      {conditionNodeDetails != undefined && (
        <>
          {conditionNodeDetails.map((option, key) => {
            return (
              <div
                className="checkbox-inline"
                key={option?.[columnName] + "_" + key}
              >
                <label className="checkbox">
                  <input
                    type={"checkbox"}
                    name={option?.[columnName]}
                    value={option?._id}
                    onChange={(e) => {
                      handleValue(e);
                    }}
                    checked={selectedValue.indexOf(option?._id) > -1}
                    disabled={linkLabel === "yes"}
                  />
                  <span className=""></span>
                  <div
                    style={
                      selectedValue.indexOf(option?.[columnName]) > -1
                        ? { color: "#E57F0E" }
                        : {}
                    }
                    className="pl-3 font-weight-bolder"
                  >
                    {option?.[columnName]}
                  </div>
                </label>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default MultiOption;
