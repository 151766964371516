import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTopicsCount } from "../../../../app/modules/Topic/_redux/topicSelectors";
import { toAbsoluteUrl } from "../../../_helpers";
import { Services } from "../../../../_service/Services";

export function MixedWidget1({ className }) {
  const topicCount = useSelector(getTopicsCount);
  const [countDetails, setCountDetails] = useState({});

  useEffect(() => {
    Services({
      url: "/admin/dashboard",
      method: "GET",
    }).then((res) => {
      setCountDetails(res?.data?.data);
    });
  }, []);

  return (
    <div className="row m-0">
      <div className="col bg-light-primary px-6 py-8 rounded-xl mr-7 mb-7">
        <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
          <div className="flex-column">
            <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
              ></SVG>
            </span>
            <Link
              to="/toolbox"
              className="text-primary font-weight-bold font-size-h6"
            >
              Toolbox
            </Link>
          </div>
          <div className="font-weight-bolder" style={{ fontSize: "40px" }}>
            {countDetails?.topicCount}
          </div>
        </div>
      </div>
      <div className="col bg-light-success px-6 py-8 rounded-xl mb-7">
        <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
          <div className="flex-column">
            <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              ></SVG>
            </span>
            <Link
              to="/users"
              className="text-success font-weight-bold font-size-h6"
            >
              Users
            </Link>
          </div>
          <div className="font-weight-bolder" style={{ fontSize: "40px" }}>
            {countDetails?.userCount}
          </div>
        </div>
      </div>
      <div className="col bg-light-primary px-6 py-8 rounded-xl ml-7 mb-7">
        <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
          <div className="flex-column">
            <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
              ></SVG>
            </span>
            <a className="text-primary font-weight-bold font-size-h6">Report</a>
          </div>
          <div className="font-weight-bolder" style={{ fontSize: "40px" }}>
            {countDetails?.reportCount}
          </div>
        </div>
      </div>
    </div>
  );
}
