import React, { useEffect, useState, useRef } from "react";
import Chip from '@material-ui/core/Chip';
// import "./travers.scss";
export function CustomToolTip(props) {
    return <div id='myTooltipDiv' className='tooltip' style={{ top: props.toolTipPosition.y, left: props.toolTipPosition.x }}>
        <h6>{props.toolTipText.text}</h6>
        {props.toolTipText.data !== undefined && props.toolTipText.data.length > 0 && props.toolTipText.data.map((item) => {
            return <Chip key={item.name} className="tooltip-chip" label={item.name} variant="outlined" />
        })}
    </div>
}

