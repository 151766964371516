import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { actions as Questions } from "../../../app/modules/Question/_redux/questionsRedux";
import "./SlickCss/slick.css";
import "./SlickCss/slick-theme.css";

function SubCategorySlider({ name, questions }) {
  const dispatch = useDispatch();
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const CategorySlider = {
    dots: false,
    infinite: questions.length > 5 ? true : false,
    speed: 100,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="SubCategorySlider__main">
      <Slider {...CategorySlider}>
        {questions.length > 0 &&
          questions.map((question) => {
            return (
              <div key={question._id} className="question__card">
                <Link to={"/traversal/" + question._id}>
                  <div
                    className="whitebox"
                    onClick={() => {
                      const newDataForQuestion = {
                        ...question,
                      };
                      dispatch(Questions.isRedirect(false));
                      dispatch(Questions.questionDetails(newDataForQuestion));
                    }}
                    onMouseEnter={() => setSelectedQuestion(question)}
                    onMouseLeave={() => setSelectedQuestion(null)}
                  >
                    <div className="box__title">
                      <span className="que__text">{question.text}</span>
                      <span className="arrownext"></span>
                    </div>
                    <div className="boximg">
                      <img
                        alt="illustration"
                        src={
                          question.icon_logo != ""
                            ? question.icon_logo
                            : "/media/misc/fleetrater-img.png"
                        }
                      />
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </Slider>
      {selectedQuestion && (
        <div className="SubCategory__hover__bluebox">
          <h4>{selectedQuestion?.text || questions[0]?.text}</h4>
          <div className="bluebox__contant">
            <label>Tag line</label>
            <p>
              {selectedQuestion?.screen_tagline || questions[0]?.screen_tagline}
            </p>
            <label>Assumption</label>
            <div className="scroll__hidden">
              <div
                className="description-text"
                dangerouslySetInnerHTML={{
                  __html:
                    selectedQuestion?.assumptions || questions[0]?.assumptions,
                }}
              ></div>
            </div>
            <label>Description</label>

            <div className="scroll__hidden">
              <div
                className="description-text"
                dangerouslySetInnerHTML={{
                  __html:
                    selectedQuestion?.description || questions[0]?.description,
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default SubCategorySlider;
