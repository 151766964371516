import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./ToolboxCategory.scss";

import { getOrganizationsList } from "../../../app/modules/Organizations/_redux/organizationsSelectors";
import { actions as SubTopics } from "../../../app/modules/SubTopic/_redux/subtopicsRedux";
import { getTopicDetails } from "../../../app/modules/SubTopic/_redux/subtopicsSelectors";
import HelpIcon from "../Help/HelpIcon";
export function ToolboxCategory() {
  const topicDetails = useSelector(getTopicDetails);
  const organization = useSelector(getOrganizationsList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SubTopics.getTopicDetails(""));
  }, []);
  // console.log(topicDetails);
  return (
    <div className="ToolboxCategory__main">
      <div className="ToolboxCategory__fullbg"></div>
      <div className="Toolbox__top__contant">
        <h3>The Executive Toolkit Helps Unlock Your Potential .....</h3>
        <p>
          Our solutions prompt High-Level questions from which Strategic Answers
          are provided ..... More Precise answer can be provided through
          customized studies{" "}
        </p>
      </div>
      <div className="posifixed__block">
        <div className="comp-logo">
          <img alt="logoicon" src={organization?.iconLogo} />
        </div>
      </div>
      <div className="Category__Directory__card">
        <h3>Directory of Solutions .....</h3>
        <div className="scroll__hidden">
          <div className="Category__DirectoryList">
            {topicDetails?.subTopics.length > 0 &&
              topicDetails?.subTopics.map((subTool) => {
                return (
                  <div key={subTool._id} className="ListName">
                    <Link to={"toolbox/solution/" + subTool._id}>
                      {subTool.name}
                    </Link>
                    <div className="List__rightCol">
                      <span className="ListCount">{subTool.questionCount}</span>
                      <div className="Listarrow"></div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <Link
          to="/logout"
          className="btn btn-success font-weight-bold btn__logout"
        >
          Logout
        </Link>
      </div>
      <div className="Toolbox__bottom__contant">
        <div className="contant__text">
          <h3>Let us Transport You to the World of Solution Development</h3>
          <p>
            Unlock the value of the Executive Toolkit for the Focus Area of
            Interest
          </p>
        </div>
        {/* <div className="pow-logo">
          <img alt="powemate" src="/media/logos/pow-emate.png" />
        </div> */}
      </div>
      <HelpIcon pageName={"UserToolBoxSection"} />
    </div>
  );
}
