/** @format */
// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from '@material-ui/core/Modal';
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import * as go from "gojs";
import { IconButton, StylesProvider } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { ArrowBackIos } from "@material-ui/icons";
import { ReactDiagram, ReactPalette } from "gojs-react";
import { useImmer } from "use-immer";
import { actions as TRAVERS_HISTORY_ACTIONS } from "../../../app/modules/TraversHistory/_redux/TraversHistoryRedux";
import { toAbsoluteUrl } from "../../_helpers";
import "./travers.scss";
import { CustomToolTip } from "./CustomTooltip"

import { user } from "../../../_service/users/user.service";
import { TraversHistoryDialoge } from "../modals/traversHistory-Dialog/TraversHistoryDialoge";
export function TraversHistory() {
  const diagramRef = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const diagramDetails = useSelector(
    (store) => store.traversHistory.diagramData
  );
  const isLoading = useSelector((store) => store.traversHistory.isLoading);
  const userId = useSelector((store) => store.traversHistory.diagramData.userId);
  const userFirstName = useSelector((store) => store.traversHistory.diagramData.firstName);
  const userLastName = useSelector((store) => store.traversHistory.diagramData.lastName);
  const question = useSelector((store) => store.traversHistory.diagramData.question);
  const [showToolTip, setShowToolTip] = useState(false);
  const [toolTipText, setToolTipText] = useState({ text: "", isMultiOption: false, data: [] });
  const [toolTipPosition, setToolTipPosition] = useState({ x: 0, y: 0 });
  const [selectedNode, setSelectedNode] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  
  useEffect(() => {
    dispatch(TRAVERS_HISTORY_ACTIONS.getDiagramTraversHistoryDetail(id))
  }, []);
  useEffect(() => {
    updateDiagramData(draft => {
      draft.nodeDataArray = diagramDetails.nodeDataArray
      draft.linkDataArray = diagramDetails.linkDataArray
    })
  
    

  }, [diagramDetails])
  const [diagramData, updateDiagramData] = useImmer({
    nodeDataArray: [],
    linkDataArray: [],
    paletteDataArray: [
      { category: "Start", text: "Start", name: "Start" },
      { text: "Step", name: "Step" },
      { category: "Conditional", text: "???", name: "Decision" },
      { category: "End", text: "End", name: "End" },
      { category: "Triangle", text: "Triangle", name: "Triangle" },
      { category: "Rectangle", text: "Rectangle", name: "Rectangle" },
      { category: "MultiOption", text: "MultiOption", name: "MultiOption" },
      { category: "Procedure", text: "Predefined Process", name: "ProcessData" },
      { category: "PredefinedLogic", text: "Predefined Logic", name: "PredefinedLogic" },
      { category: "Parallelogram", text: "Data Load", name: "FileInput" },
      { category: "ManualInput", text: "Manual Input", name: "ManualInput" },
      { category: "Cylinder1", text: "Store Data", name: "StoreData" },
      { category: "MultiDocument", text: "Report", name: "Report" },
      { category: "Document", text: "Final Read Out", name: "FinalReadOut" },
      { category: "MultiProcess", text: "MultiProcess", name: "MultiProcess" },
      { category: "Comment", text: "Comment", name: "Comment" },
    ],
    modelData: { canRelink: true },
    selectedData: null,
    skipsDiagramUpdate: false,
    mapNodeKeyIdx: {},
    mapLinkKeyIdx: {},
  });

  //   useEffect(() => {
  //     updateDiagramData(draft => {
  //       draft.nodeDataArray = diagramDetails.nodeDataArray
  //       draft.linkDataArray= diagramDetails.linkDataArray
  //     })
  //   },[diagramDetails])
  const showLinkLabel = (e) => {
    const label = e.subject.findObject("LABEL");
    if (label !== null)
      label.visible = e.subject.fromNode.data.category === "Conditional";
  };

  const $ = go.GraphObject.make;
  const diagram = $(go.Diagram, {
    "undoManager.isEnabled": true,
    LinkDrawn: showLinkLabel,
    LinkRelinked: showLinkLabel,
    model: $(go.GraphLinksModel, {
      linkKeyProperty: "key",
    }),

  });

  diagram.addDiagramListener("ObjectSingleClicked",
    function (e) {
      var part = e.subject.part;
      if (!(part instanceof go.Link)) {
        let toolTipTextData = {}
        var e = diagram.lastInput;
        var x = (diagramRef.current.getDiagram()?.div?.offsetLeft ?? 0) + e.viewPoint.x + 5;
        var y = (diagramRef.current.getDiagram()?.div?.offsetTop ?? 0) + e.viewPoint.y - 40;
        setToolTipPosition({ x: x, y: y })
        switch (part.data.category) {
          case "Conditional":
            toolTipTextData = {
              text: part.data.text, isMultiOption: false, data: []
            };
            break;
          case "PredefinedLogic":
            toolTipTextData = {
              text: part.data.text, isMultiOption: false, data: []
            };
            break;
          case "Procedure":
            toolTipTextData = {
              text: part.data.text, isMultiOption: false, data: []
            };
            break;
          case "MultiOption":
            toolTipTextData = {
              text: part.data.text, isMultiOption: true, data: part.data.metaData.value
            };
            break;
          case "Parallelogram":
            toolTipTextData = {
              fileName: "",
              server: "",
              frequency: "",
            };
            break;
          case "ManualInput":
          case "Rectangle":
            let text = part.data.metaData.label
            let value = '';
            if (part.data.metaData.value !== undefined) {
              value = '-' + part.data.metaData.value
            }
            toolTipTextData = {
              text: text + value, isMultiOption: false, data: []
            };
            break;
          default:
            toolTipTextData = {};
        }
        // toolTipTextData.text = part.data.text
        if (part.data.category !== 'MultiDocument') {
          setToolTipText(toolTipTextData)
          setShowToolTip(true)
        }
      }
    });

  const formArray = ["Conditional", "PredefinedLogic", "Procedure", "Parallelogram", "MultiOption", "ManualInput", "Rectangle"]


  diagram.addDiagramListener("ChangedSelection", function (e) {
    //   to hide form
    // setFormChange(false);
    // set null for not to show form
    // setFormToShow(null);
    //refresh Link Index
    // refreshLinkIndex(diagramData.linkDataArray);
    setShowToolTip(false)
  });

  const initDiagram = () => {
    var KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);
    function FigureParameter(name, def, min, max) {
      if (min === undefined /*notpresent*/) min = 0.0;
      if (max === undefined /*notpresent*/) max = Infinity;
      /** @type {string} */
      this._name = name;
      /** @type {number} */
      this._defaultValue = def;
      /** @type {number} */
      this._minimum = min;
      /** @type {number} */
      this._maximum = max;
    }

    go.Shape.defineFigureGenerator("Cylinder1", function (shape, w, h) {
      var param1 = shape ? shape.parameter1 : NaN; // half the height of the ellipse
      if (isNaN(param1)) param1 = 5; // default value
      param1 = Math.min(param1, h / 3);

      var geo = new go.Geometry();
      var cpxOffset = KAPPA * 0.5;
      var fig = new go.PathFigure(0, param1, true);
      geo.add(fig);
      // The base (top)
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0,
          0,
          KAPPA * param1,
          (0.5 - cpxOffset) * w,
          0
        )
      );
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          1.0 * w,
          param1,
          (0.5 + cpxOffset) * w,
          0,
          1.0 * w,
          KAPPA * param1
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h - param1));
      // Bottom curve
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          1.0 * h,
          1.0 * w,
          h - KAPPA * param1,
          (0.5 + cpxOffset) * w,
          1.0 * h
        )
      );
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          h - param1,
          (0.5 - cpxOffset) * w,
          1.0 * h,
          0,
          h - KAPPA * param1
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, param1));

      var fig2 = new go.PathFigure(w, param1, false);
      geo.add(fig2);
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          2 * param1,
          1.0 * w,
          2 * param1 - KAPPA * param1,
          (0.5 + cpxOffset) * w,
          2 * param1
        )
      );
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          param1,
          (0.5 - cpxOffset) * w,
          2 * param1,
          0,
          2 * param1 - KAPPA * param1
        )
      );

      geo.spot1 = new go.Spot(0, 0, 0, 2 * param1);
      geo.spot2 = new go.Spot(1, 1);
      return geo;
    });

    go.Shape.defineFigureGenerator("MultiDocument", function (shape, w, h) {
      var geo = new go.Geometry();
      h = h / 0.8;
      var fig = new go.PathFigure(w, 0, true);
      geo.add(fig);

      // Outline
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.5 * h));
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.9 * w,
          0.44 * h,
          0.96 * w,
          0.47 * h,
          0.93 * w,
          0.45 * h
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.6 * h));
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.8 * w,
          0.54 * h,
          0.86 * w,
          0.57 * h,
          0.83 * w,
          0.55 * h
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.7 * h));
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          0.7 * h,
          0.4 * w,
          0.4 * h,
          0.4 * w,
          h
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.2 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.1 * w, 0.2 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.1 * w, 0.1 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.2 * w, 0.1 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.2 * w, 0).close());
      var fig2 = new go.PathFigure(0.1 * w, 0.2 * h, false);
      geo.add(fig2);
      // Inside lines
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.2 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.54 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, 0.2 * w, 0.1 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.1 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.44 * h));
      geo.spot1 = new go.Spot(0, 0.25);
      geo.spot2 = new go.Spot(0.8, 0.77);
      return geo;
    });

    go.Shape.defineFigureGenerator("MultiProcess", function (shape, w, h) {
      var geo = new go.Geometry();
      var fig = new go.PathFigure(0.1 * w, 0.1 * h, true);
      geo.add(fig);

      fig.add(new go.PathSegment(go.PathSegment.Line, 0.2 * w, 0.1 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.2 * w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.8 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.8 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.9 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.9 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.2 * h));
      fig.add(
        new go.PathSegment(go.PathSegment.Line, 0.1 * w, 0.2 * h).close()
      );
      var fig2 = new go.PathFigure(0.2 * w, 0.1 * h, false);
      geo.add(fig2);
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.1 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.8 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, 0.1 * w, 0.2 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.2 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.9 * h));
      geo.spot1 = new go.Spot(0, 0.2);
      geo.spot2 = new go.Spot(0.8, 1);
      return geo;
    });

    go.Shape.defineFigureGenerator("Document", function (shape, w, h) {
      var geo = new go.Geometry();
      h = h / 0.8;
      var fig = new go.PathFigure(0, 0.7 * h, true);
      geo.add(fig);

      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.7 * h));
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          0.7 * h,
          0.5 * w,
          0.4 * h,
          0.5 * w,
          h
        ).close()
      );
      geo.spot1 = go.Spot.TopLeft;
      geo.spot2 = new go.Spot(1, 0.6);
      return geo;
    });

    go.Shape.defineFigureGenerator("ManualInput", function (shape, w, h) {
      var geo = new go.Geometry();
      var fig = new go.PathFigure(w, 0, true);
      geo.add(fig);

      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.25 * h).close());
      geo.spot1 = new go.Spot(0, 0.25);
      geo.spot2 = go.Spot.BottomRight;
      return geo;
    });
    go.Shape.getFigureParameter = function (figurename, index) {
      var arr = go.Shape._FigureParameters[figurename];
      if (!arr) return null;
      return /** @type {FigureParmeter} */ (arr[index]);
    };
    go.Shape.setFigureParameter = function (figurename, index, figparam) {
      if (!(figparam instanceof FigureParameter))
        throw new Error(
          "Third argument to Shape.setFigureParameter is not FigureParameter: " +
          figparam
        );
      if (
        figparam.defaultValue < figparam.minimum ||
        figparam.defaultValue > figparam.maximum
      )
        throw new Error(
          "defaultValue must be between minimum and maximum, not: " +
          figparam.defaultValue
        );
      var arr = go.Shape._FigureParameters[figurename];
      if (!arr) {
        arr = [];
        go.Shape._FigureParameters[figurename] = arr;
      }
      arr[index] = figparam;
    };
    go.Shape.defineFigureGenerator("Procedure", function (shape, w, h) {
      var geo = new go.Geometry();
      var param1 = shape ? shape.parameter1 : NaN;
      // Distance of left  and right lines from edge
      if (isNaN(param1)) param1 = 0.1;
      var fig = new go.PathFigure(0, 0, true);
      geo.add(fig);

      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      var fig2 = new go.PathFigure((1 - param1) * w, 0, false);
      geo.add(fig2);
      fig2.add(new go.PathSegment(go.PathSegment.Line, (1 - param1) * w, h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, param1 * w, 0));
      fig2.add(new go.PathSegment(go.PathSegment.Line, param1 * w, h));
      //??? geo.spot1 = new go.Spot(param1, 0);
      //??? geo.spot2 = new go.Spot(1 - param1, 1);
      return geo;
    });
    go.Shape.defineFigureGenerator("PredefinedLogic", function (shape, w, h) {
      var geo = new go.Geometry();
      var param1 = shape ? shape.parameter1 : NaN;
      if (isNaN(param1)) param1 = .1;
      var fig = new go.PathFigure(0, 0, true);
      geo.add(fig);

      // Body
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      var fig2 = new go.PathFigure(0, param1 * h, false);
      geo.add(fig2);
      // Inside lines
      fig2.add(new go.PathSegment(go.PathSegment.Line, w, param1 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, 0, (1 - param1) * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, w, (1 - param1) * h));
      //??? geo.spot1 = new go.Spot(0, param1);
      //??? geo.spot2 = new go.Spot(1, 1 - param1);
      return geo;
    });
    go.Shape.defineFigureGenerator("MultiOption", function (shape, w, h) {
      var geo = new go.Geometry();
      var fig = new go.PathFigure(0, 0, true);
      geo.add(fig);
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      return geo;
    });
    go.Shape._FigureParameters = {};
    go.Shape.setFigureParameter(
      "Parallelogram",
      0,
      new FigureParameter("Indent", 10, -Infinity, Infinity)
    );
    go.Shape.defineFigureGenerator("Parallelogram", function (shape, w, h) {
      var param1 = shape ? shape.parameter1 : NaN; // indent's x distance
      if (isNaN(param1)) param1 = 10;
      else if (param1 < -w) param1 = -w;
      else if (param1 > w) param1 = w;
      var indent = Math.abs(param1);
      var geo = new go.Geometry()
      if (param1 === 0) {
        geo = new go.Geometry(go.Geometry.Rectangle);
        geo.startX = 0;
        geo.startY = 0;
        geo.endX = w;
        geo.endY = h;
        return geo;
      } else {

        if (param1 > 0) {
          geo.add(
            new go.PathFigure(indent, 0)
              .add(new go.PathSegment(go.PathSegment.Line, w, 0))
              .add(new go.PathSegment(go.PathSegment.Line, w - indent, h))
              .add(new go.PathSegment(go.PathSegment.Line, 0, h).close())
          );
        } else {
          // param1 < 0
          geo.add(
            new go.PathFigure(0, 0)
              .add(new go.PathSegment(go.PathSegment.Line, w - indent, 0))
              .add(new go.PathSegment(go.PathSegment.Line, w, h))
              .add(new go.PathSegment(go.PathSegment.Line, indent, h).close())
          );
        }
        if (indent < w / 2) {
          geo.setSpots(indent / w, 0, (w - indent) / w, 1);
        }
        return geo;
      }
    });
    diagram.nodeTemplate = $(
      go.Node,
      "Auto",
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      $(
        go.Shape,
        "RoundedRectangle",
        {
          name: "SHAPE",
          fill: "lightgreen",
          strokeWidth: 1,
        },
        new go.Binding("fill", "color")
      ),
      $(
        go.TextBlock,
        { margin: 8, editable: false },
        new go.Binding("text").makeTwoWay()
      ),

    );



    const nodeStyle = () => [
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      {
        locationSpot: go.Spot.Center,
      },
    ];

    // 不同分类的形状
    const makePort = (name, align, spot, output, input) => {
      const horizontal =
        align.equals(go.Spot.Top) || align.equals(go.Spot.Bottom);
      return $(go.Shape, {
        fill: "transparent",
        strokeWidth: 0,
        width: horizontal ? NaN : 8,
        height: !horizontal ? NaN : 8,
        alignment: align,
        stretch: horizontal
          ? go.GraphObject.Horizontal
          : go.GraphObject.Vertical,
        portId: name,
        fromSpot: spot,
        fromLinkable: output,
        toSpot: spot,
        toLinkable: input,
        cursor: "pointer",
        mouseEnter(e, port) {
          port.fill = "transparent";
        },
        mouseLeave(e, port) {
          port.fill = "transparent";
        },
      });
    };

    const textStyle = () => ({
      font: "bold 12px Lato, Helvetica, Arial, sans-serif",
      stroke: "#000000",
    });

    // 默认分类
    diagram.nodeTemplateMap.add(
      "",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Auto",
          $(
            go.Shape,
            "Rectangle",
            {
              fill: "#eaeaea",
              stroke: "#000000",
              strokeWidth: 1,
              width: 105,
              height: 65,
            },
            new go.Binding("figure", "figure")
          ),
          $(
            go.TextBlock,
            textStyle(),
            {
              margin: 8,
              maxSize: new go.Size(200, NaN),
              wrap: go.TextBlock.WrapFit,
              editable: false,
            },
            new go.Binding("text").makeTwoWay()
          )
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, false)
      )
    );

    diagram.nodeTemplateMap.add(
      "Conditional",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Auto",
          $(
            go.Shape,
            "Diamond",
            {
              fill: "#eaeaea",
              stroke: "#000000",
              strokeWidth: 1,
              width: 105,
              height: 65,
            },
            new go.Binding("figure", "figure"),
            new go.Binding("fill", "color")
          ),
          $(
            go.TextBlock,
            textStyle(),
            {
              margin: 8,
              maxSize: new go.Size(200, NaN),
              wrap: go.TextBlock.WrapFit,
              editable: false,
            },
            new go.Binding("text").makeTwoWay()
          )
        ),
        makePort("T", go.Spot.Top, go.Spot.Top, false, true),
        makePort("L", go.Spot.Left, go.Spot.Left, true, true),
        makePort("R", go.Spot.Right, go.Spot.Right, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.Bottom, true, false)
      )
    );

    diagram.nodeTemplateMap.add(
      "Start",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Spot",
          $(go.Shape, "Circle", {
            desiredSize: new go.Size(75, NaN),
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 75,
            height: 75,
          },
            new go.Binding("fill", "color")
          ),
          $(go.TextBlock, "Start", textStyle(), new go.Binding("text"))
        ),
        makePort("L", go.Spot.Left, go.Spot.Left, true, true),
        makePort("R", go.Spot.Right, go.Spot.Right, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.Bottom, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "End",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Spot",
          $(go.Shape, "RoundedRectangle", {
            desiredSize: new go.Size(75, NaN),
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 75,
            height: 65,
          }),
          $(go.TextBlock, "End", textStyle(), new go.Binding("text"))
        ),
        makePort("T", go.Spot.Top, go.Spot.Top, false, true),
        makePort("L", go.Spot.Left, go.Spot.Left, false, true),
        makePort("R", go.Spot.Right, go.Spot.Right, false, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "Triangle",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Spot",
          $(go.Shape, "Triangle", {
            desiredSize: new go.Size(105, NaN),
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
            height: 65,
          }),
          $(go.TextBlock, "End", textStyle(), new go.Binding("text"))
        ),
        makePort("T", go.Spot.Top, go.Spot.Top, false, true),
        makePort("L", go.Spot.Left, go.Spot.Left, true, true),
        makePort("R", go.Spot.Right, go.Spot.Right, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.Bottom, true, false)
      )
    );

    go.Shape.defineFigureGenerator("File", (shape, w, h) => {
      const geo = new go.Geometry();
      const fig = new go.PathFigure(0, 0, true);
      geo.add(fig);
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.75 * w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.25 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      const fig2 = new go.PathFigure(0.75 * w, 0, false);
      geo.add(fig2);
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.75 * w, 0.25 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, w, 0.25 * h));
      geo.spot1 = new go.Spot(0, 0.25);
      geo.spot2 = go.Spot.BottomRight;
      return geo;
    });

    diagram.nodeTemplateMap.add(
      "Comment",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "File", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          height: 65,
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: true,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.Top, true, true),
        makePort("L", go.Spot.Left, go.Spot.Left, true, true),
        makePort("R", go.Spot.Right, go.Spot.Right, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "Rectangle",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "Rectangle", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          height: 65,
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, false)
      )
    );


    diagram.nodeTemplateMap.add(
      "Procedure",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "Procedure", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          height: 65,
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, false)
      )
    );
    diagram.nodeTemplateMap.add(
      "PredefinedLogic",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "PredefinedLogic", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          height: 65,
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, false)
      )
    );
    diagram.nodeTemplateMap.add(
      "MultiOption",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "MultiOption", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          minSize: new go.Size(105, 95),
          maxSize: new go.Size(105, NaN),
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: new go.Margin(10, 0, 0, 0),
            maxSize: new go.Size(105, NaN),
            alignment: go.Spot.Top,
            editable: false,
            isMultiline: true,
          },
          new go.Binding("text").makeTwoWay()
        ),
        $(go.Picture, "../../../media/svg/multioptionv2.svg",
          { alignment: go.Spot.Bottom, margin: 10 },
          { width: 54 },
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );
    diagram.nodeTemplateMap.add(
      "Parallelogram",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "Parallelogram", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          height: 65,
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 10,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,

          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, false)
      )
    );
    diagram.nodeTemplateMap.add(
      "ManualInput",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "ManualInput", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          height: 65,
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "Cylinder1",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "Cylinder1", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          height: 65,
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: true,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );
    diagram.nodeTemplateMap.add(
      "MultiDocument",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "MultiDocument", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          height: 65,
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: true,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, false)
      )
    );

    diagram.nodeTemplateMap.add(
      "Document",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "Document", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          height: 65,
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, false)
      )
    );

    diagram.nodeTemplateMap.add(
      "MultiProcess",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(go.Shape, "MultiProcess", {
          fill: "#eaeaea",
          stroke: "#000000",
          strokeWidth: 1,
          width: 105,
          height: 65,
        }),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, false)
      )
    );

    // 连接线处理
    diagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        curve: go.Link.JumpOver,
        corner: 5,
        toShortLength: 4,
        relinkableFrom: true,
        relinkableTo: true,
        reshapable: true,
        resegmentable: true,

        selectionAdorned: false,
      },
      new go.Binding("points").makeTwoWay(),
      $(go.Shape, {
        isPanelMain: true,
        strokeWidth: 8,
        stroke: "transparent",
        name: "HIGHLIGHT",
      }, new go.Binding("stroke", "selected", (sel) => sel ? "yellow" : "gray"),
        new go.AnimationTrigger('stroke')
      ),
      $(
        go.Shape,
        {
          isPanelMain: true,
          stroke: "gray",
          strokeWidth: 2,
        },

      ),
      $(go.Shape, {
        toArrow: "standard",
        strokeWidth: 0,
        fill: "gray",
      }),
      $(
        go.Panel,
        "Auto",
        {
          visible: false,
          name: "LABEL",
          segmentIndex: 2,
          segmentFraction: 0.5,
        },
        new go.Binding("visible", "visible").makeTwoWay(),
        $(go.Shape, "RoundedRectangle", {
          fill: "#F8F8F8",
          strokeWidth: 0,
        }),
        $(
          go.TextBlock,
          {
            textAlign: "center",
            font: "10pt helvetica, arial, sans-serif",
            stroke: "#000000",
            editable: true,
          },
          new go.Binding("text").makeTwoWay()
        )
      ),

    );

    diagram.toolManager.linkingTool.temporaryLink.routing = go.Link.Orthogonal;
    diagram.toolManager.relinkingTool.temporaryLink.routing =
      go.Link.Orthogonal;

    return diagram;
  };

  diagram.isEnabled = true
  diagram.isReadOnly = true
  const modelChangeHandler = (obj) => {
    const insertedNodeKeys = obj.insertedNodeKeys;
    const modifiedNodeData = obj.modifiedNodeData;
    const removedNodeKeys = obj.removedNodeKeys;
    const insertedLinkKeys = obj.insertedLinkKeys;
    const modifiedLinkData = obj.modifiedLinkData;
    const removedLinkKeys = obj.removedLinkKeys;
    const modifiedModelData = obj.modelData;

    // maintain maps of modified data so insertions don't need slow lookups
    const modifiedNodeMap = new Map();
    const modifiedLinkMap = new Map();
    updateDiagramData(draft => {
      let checkForLink = false;

      let narr = draft.nodeDataArray;
      let mapNodeKeyIdx = draft.mapNodeKeyIdx;

      if (insertedNodeKeys) {
        insertedNodeKeys.forEach((key) => {
          let nd = modifiedNodeMap.get(key);
          const idx = Object.keys(mapNodeKeyIdx).find((v) => v === key);
          let index = narr.findIndex((item) => item.key === key);

          if (nd === undefined && idx === undefined && index < 0) { // nodes won't be added if they already exist
            nd = modifiedNodeData[0];
            mapNodeKeyIdx[key] = narr.length;

            // to add form fields in node
            // nd.metaData = addDiagramForm(nd.category);
            narr.push(nd);
          }
        });
      }

      if (modifiedNodeData) {
        checkForLink = true;
        modifiedNodeData.forEach((nd) => {
          modifiedNodeMap.set(nd.key, nd);
          const idx = Object.keys(mapNodeKeyIdx).findIndex((v) => parseInt(v) === nd.key)
          if (idx !== undefined && idx >= 0 && JSON.stringify(nd) !== JSON.stringify(narr[idx])) {
            narr[idx] = nd;
            if (draft.selectedData && draft.selectedData.key === nd.key) {
              draft.selectedData = nd;
            }
          }
        });
      }

      if (removedNodeKeys) {
        narr = narr.filter((nd) => {
          if (removedNodeKeys.includes(nd.key)) {
            return false;
          }
          return true;
        });
        removedNodeKeys.forEach((key) => delete mapNodeKeyIdx[key])
        draft.nodeDataArray = narr;
      }

      let larr = draft.linkDataArray;
      let mapLinkKeyIdx = draft.mapLinkKeyIdx;

      if (insertedLinkKeys) {
        insertedLinkKeys.forEach((key) => {
          let ld = modifiedLinkMap.get(key);
          const idx = Object.keys(mapLinkKeyIdx).find((v) => v === key);
          let index = larr.findIndex((item) => item.key === key);
          if (ld === undefined && idx === undefined && index < 0) {  // links won't be added if they already exist
            ld = modifiedLinkData[0];
            mapLinkKeyIdx[key] = larr.length;
            larr.push(ld);
          }
        });
      }

      if (modifiedLinkData) {
        modifiedLinkData.forEach((ld) => {
          modifiedLinkMap.set(ld.key, ld);
          const idx = Object.keys(mapLinkKeyIdx).findIndex((v) => parseInt(v) === ld.key);

          // to check if a text is added between link
          if (ld?.text !== undefined && larr[idx]?.text !== ld?.text && checkForLink === false) {
            checkForLink = true;
          }


          if (idx !== undefined && idx >= 0 && checkForLink === true) {
            // if (idx !== undefined && idx >= 0 && JSON.stringify(ld) !== JSON.stringify(larr[idx])) {
            larr[idx] = ld;
            if (draft.selectedData && draft.selectedData.key == ld.key) {
              draft.selectedData = ld;
            }
          }
        });
      }

      if (removedLinkKeys) {
        larr = larr.filter((ld) => {
          if (removedLinkKeys.includes(ld.key)) {
            return false;
          }
          return true;
        });
        removedLinkKeys.forEach((key) => delete mapLinkKeyIdx[key])
        draft.linkDataArray = larr;
      }

      // handle model data changes, for now just replacing with the supplied object
      if (modifiedModelData) {
        draft.modelData = modifiedModelData;
      }
      draft.skipsDiagramUpdate = true;  // the GoJS model already knows about these updates
    });
  };
  


  return (
    <div className={`card-custom`}>
      <div className="card-header border-0 py-5 custom-header d-flex align-items-center justify-content-between">
        <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
            onClick={history.goBack}
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              aria-label="detail"
              style={{
                marginRight: 10,
                height: 34,
                width: 34,
                borderRadius: 5,
                color: "#3699FF",
              }}
            >
              <ArrowBackIos className="back" />
            </IconButton>
          <h3 className="card-title flex-column m-0">
            <span className="card-label font-weight-bolder text-dark ">
              {question !== "" ? question : ""} (<small>{userFirstName} {userLastName}</small>)
            </span>
          </h3>
        </div>
        <IconButton
          onClick={() => setModalShow(true)}
          aria-label="detail"
        >
          <Visibility style={{ color: "#3699FF" }} />
        </IconButton>
      </div>
      <div className="pt-6">
        <div className="travers-row">

          <ReactDiagram
            initDiagram={initDiagram}
            ref={diagramRef}
            divClassName="travers-col"
            linkFromPortIdProperty="fromPort"
            linkToPortIdProperty="toPort"
            nodeDataArray={diagramData.nodeDataArray}
            linkDataArray={diagramData.linkDataArray}
            onModelChange={modelChangeHandler}
          />
        </div>
        <div>
          {/* <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={formChange}
            onClose={handleClose}
          >
            <Form
              save={handleSaveData}
              nodeDataArray={selectedNode}
              formToShow={formToShow}
            />
          </Modal> */}
          {/* Form Component */}
          {/* {formChange && (
            
          )} */}
        </div>
      </div>
      {modalShow &&
        <TraversHistoryDialoge show={modalShow}
          toggleModal={(value) => setModalShow(value)}
          traversId={id}/>
      }

      {showToolTip && <>
        <CustomToolTip toolTipPosition={toolTipPosition} toolTipText={toolTipText} />
      </>
      }
    </div>
  );
}