import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import SVG from "react-inlinesvg";
import RingLoader from "react-spinners/RingLoader";

import { toAbsoluteUrl } from "../../../_helpers";
import { Services } from "../../../../_service/Services";
import { setToast } from "../../../../app/modules/toast";

import { TraversHistoryDialoge } from "../../modals/traversHistory-Dialog/TraversHistoryDialoge";
import { HistoryReportDialogue } from "../../modals/HistoryReportDialogue/HistoryReportDialogue";

import { format } from "date-fns";
import parseISO from "date-fns/parseISO";

import { ArrowBackIos } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import "./common.css";

export function HistoryTraversalTableWidget({ className }) {
  const { id, question: title } = useParams();
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [traversId, setTraversId] = useState("");
  const [reportShow, setReportShow] = useState(false);
  const [transactionId, setTransactionId] = useState("");

  const history = useHistory();

  const fetchDetails = (id) => {
    setTraversId(id);
    setModalShow(true);
  };

  useEffect(() => {
    Services({
      url: `/user/history/list?questionId=${id}`,
      method: "GET",
    })
      .then((res) => {
        setQuestions(res?.data?.data);
      })
      .catch((error) => {
        let message = "Report missing!";
        if (error?.response?.data?.message) {
          message = error.response.data.message;
        }
        setToast(message, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const print = (reportUrl) => {
    var file_path = reportUrl;
    var a = document.createElement("a");
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    a.click();
  };

  return (
    <>
      <div className={`card card-custom`} style={{ marginBottom: 20 }}>
        <div className="card-body py-4 d-flex align-items-center">
          <IconButton
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={history.goBack}
            aria-label="detail"
            style={{
              marginRight: 20,
              height: 34,
              width: 34,
              borderRadius: 5,
              color: "#3699FF",
            }}
          >
            <ArrowBackIos className="back" />
          </IconButton>
          <div>
            <h4 style={{ margin: 0 }}>History Listing</h4>
          </div>
        </div>
      </div>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        {isLoading && (
          <div className="loader_wrapper">
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </div>
        )}
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ minWidth: "440px" }}>
                    Toolbox Name
                  </th>
                  <th
                    style={{ minWidth: "150px", position: "sticky", right: 0 }}
                    className="pr-0 text-left"
                  >
                    Status
                  </th>
                  <th style={{ minWidth: "150px" }}>Created Date</th>
                  <th
                    style={{ minWidth: "150px", position: "sticky", right: 0 }}
                    className="pr-0 text-left"
                  >
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {(questions || []).map((question) => (
                  <tr key={`question_${question._id}`}>
                    <td className="pl-0 py-4">
                      <div className="d-flex align-items-center">
                        <div className="text-dark-75 font-weight-bolder text-hover-primary d-block mb-1 font-size-lg">
                          {question.question}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {question?.isCompleted ? (
                          <div
                            style={{
                              color: "green",
                              fontWeight: 500,
                              fontSize: 12,
                            }}
                          >
                            Completed
                          </div>
                        ) : (
                          <div
                            style={{
                              color: "#eb800a",
                              fontWeight: 500,
                              fontSize: 12,
                            }}
                          >
                            In Progress
                          </div>
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {format(parseISO(question.createdAt), "PPP p")}
                      </span>
                    </td>

                    <td
                      style={{ position: "sticky", right: 0 }}
                      className="pr-0 text-left"
                    >
                      <IconButton
                        className="btn btn-icon btn-light btn-hover-primary iconBtn"
                        onClick={fetchDetails.bind(this, question?._id)}
                        aria-label="detail"
                      >
                        <span className="svg-icon d-block p-1 view-icon">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/ico_view.svg")}
                          ></SVG>
                        </span>
                      </IconButton>
                      {question.reportUrl && (
                        <IconButton
                          onClick={(e) => {
                            setTransactionId(question?._id);
                            setReportShow(true);
                          }}
                          className="btn btn-icon btn-light btn-hover-primary iconBtn"
                        >
                          <span className="svg-icon d-block p-1 report-icon">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/ico_report.svg")}
                            ></SVG>
                          </span>
                        </IconButton>
                      )}
                      {question.reportUrl && (
                        <IconButton
                          className="btn btn-icon btn-light btn-hover-primary iconBtn"
                          onClick={print.bind(this, question.reportUrl)}
                          aria-label="detail"
                        >
                          <span className="svg-icon d-block p-1 download-icon">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/ico_download.svg")}
                            ></SVG>
                          </span>
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))}
                {!(questions || []).length && !isLoading && (
                  <tr>
                    <td className="pl-0 text-center" colSpan={8}>
                      <em>No records </em>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {modalShow && (
        <TraversHistoryDialoge
          show={modalShow}
          toggleModal={(value) => setModalShow(value)}
          traversId={traversId}
        />
      )}
      {reportShow && (
        <HistoryReportDialogue
          show={reportShow}
          toggleModal={(value) => setReportShow(value)}
          transactionId={transactionId}
        />
      )}
    </>
  );
}
