import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";

import * as FILE_UPLOAD_SERVICES from "../../../../_service/fileUpload/fileUpload.service";

export const actionTypes = {
  ADD: "[FILE_UPLOAD_ADD] Action",
  LOADING: "[FILE_UPLOAD_LOADING] Action",
  ERROR: "[FILE_UPLOAD_ERROR] Action",
  REDIRECT: "[FILE_UPLOAD_REDIRECT] Action",
};

const initialFileUploadState = {
  isLoading: false,
  error: "",
  redirect: false,
  
};

export const reducer = persistReducer(
  { storage, key: "fileUpload" },
  (state = initialFileUploadState, action) => {
    switch (action.type) {
  
      case actionTypes.LOADING: {
        const { isLoading } = action.payload;

        return { ...state, isLoading };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;

        return { ...state, error };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  add: (fileData) => ({ type: actionTypes.ADD, payload: { fileData } }),
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING,
    payload: { isLoading },
  }),
  redirect: (isTrue) => ({
    type: actionTypes.REDIRECT,
    payload: { isTrue },
  }),
  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),
  
};

export function* saga() {
  yield takeLatest(actionTypes.ADD, function* addSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { fileData } = action.payload;
      yield call(FILE_UPLOAD_SERVICES.uploadFile, fileData);
      yield put(actions.redirect(true));
    } catch (error) {
      yield put(actions.error("File Upload Fails!"));
    }
    yield put(actions.isLoading(false));
  });



}
