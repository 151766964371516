import React from "react";
import "./UserInfo.css";

export const UserInfo = ({ user }) => {
  return (
    <div className="userinfo-block">
      {/* <div className="image-layout">
        <image />
      </div> */}
      <div className="userinfo-detail">
        <h3 className="text-dark-75 text-capitalize text-hover-primary d-block mb-2 font-size-lg">{`${user?.firstName} ${user?.lastName}`}</h3>
        <div className="text-dark-50 text-hover-primary d-block mb-2">{user.email}</div>
        <span className="text-muted d-block">{`${user.countryCode} ${user.phone}`}</span>
      </div>
    </div>
  );
};
