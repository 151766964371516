import React, { useState, useEffect } from "react";
import { uniq } from "lodash";
import { RingLoader } from "react-spinners";

function MultiOptionWithGroupBy({
  conditionNodeDetails,
  columnName,
  groupByColumn,
  linkLabel,
  selectedOptionFromConditionalNode,
  className,
  isSingle,
  onFieldChange,
  reTraverseData,
  displayColumnForObject,
  fileDisplayColumn,
  fileGroupByDisplayName,
  nodeDataLength,
  optionNodeType,
}) {
  const [allProducts, setAllProducts] = useState();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectProduct, setSelectedProduct] = useState([]);

  useEffect(() => {
    if (selectProduct !== undefined) {
      onFieldChange(
        selectProduct.map((item) => {
          return { [displayColumnForObject]: item?.[displayColumnForObject] };
        })
      );
    }
  }, [selectProduct]);
  useEffect(() => {
    let optionDetails = conditionNodeDetails;
    let productsList = optionDetails.map((item) => {
      return item.list;
    });
    setAllProducts([].concat(...productsList));
    const categories = uniq(optionDetails.map((prd) => prd?.[groupByColumn]));
    setCategories(categories);
  }, [conditionNodeDetails]);

  const handleCategoryCheck = (category, isChecked) => {
    const products = allProducts.filter(
      (prd) => prd?.[groupByColumn].toString() === category.toString()
    );
    let selectedProducts = [...selectProduct];
    if (isChecked) {
      selectedProducts = selectedProducts?.filter(
        (s_prd) =>
          !products?.find(
            (prd) =>
              prd?.[displayColumnForObject].toString() ===
              s_prd?.[displayColumnForObject].toString()
          )
      );
    } else {
      const res = products?.forEach((prd) => {
        if (
          !selectedProducts?.find(
            (s_prd) =>
              s_prd?.[displayColumnForObject].toString() ===
              prd?.[displayColumnForObject].toString()
          )
        ) {
          selectedProducts.push(prd);
        }
      });
    }

    setSelectedProduct(selectedProducts);
  };

  // Select all products
  const handleCheckAll = (e) => {
    let SelectedProduct = [];
    if (e.target.checked) {
      SelectedProduct = [...allProducts];
    } else {
      SelectedProduct = [];
    }
    setSelectedProduct(SelectedProduct);
  };

  const handleProductCheck = (isChecked, product) => {
    if (!isSingle) {
      if (isChecked) {
        const products = selectProduct?.filter(
          (prd) =>
            prd?.[displayColumnForObject] !== product?.[displayColumnForObject]
        );
        setSelectedProduct(products);
      } else {
        setSelectedProduct([...(selectProduct || []), product]);
      }
    } else {
      setSelectedProduct([product]);
    }
  };

  const getChecked = (category) => {
    const products = allProducts.filter(
      (prd) => prd?.[groupByColumn].toString() === category.toString()
    );
    return products?.reduce((isChecked, prd) => {
      if (
        !selectProduct?.find(
          (s_prd) =>
            s_prd?.[displayColumnForObject].toString() ===
            prd?.[displayColumnForObject].toString()
        )
      ) {
        isChecked = false;
      }
      return isChecked;
    }, true);
  };

  const getHasSelected = (category) => {
    const products = allProducts.filter(
      (prd) => prd?.[groupByColumn].toString() === category.toString()
    );
    return products?.reduce((isChecked, prd) => {
      if (
        selectProduct?.find(
          (s_prd) =>
            s_prd?.[displayColumnForObject].toString() ===
            prd?.[displayColumnForObject].toString()
        )
      ) {
        isChecked = true;
      }
      return isChecked;
    }, false);
  };

  const onCategoryClicked = (category) => {
    setSelectedCategory(category);
    const products = allProducts.filter(
      (prd) => prd?.[groupByColumn].toString() === category.toString()
    );
    setProducts(products);
  };

  useEffect(() => {
    if (linkLabel.toLowerCase() === "no" || optionNodeType == "MultiOption") {
      const products = allProducts?.filter((prd) => prd?.isSelected === true);
      setSelectedProduct(products);
    } else {
      setSelectedProduct(allProducts);
    }
    if (reTraverseData.length > 0) {
      setSelectedProduct(reTraverseData);
    }
  }, [allProducts, selectedOptionFromConditionalNode, reTraverseData]);

  return (
    <>
      <div className="card-header border-0 py-8 custom-header">
        {/* <h3 className="card-title flex-column mb-0">
          <span className="card-label font-weight-bolder text-dark">
            Select Product Sold
          </span>
        </h3> */}
      </div>
      {isLoading ? (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      ) : (
        <div className="card-body pt-0">
          <div className={`card card-custom ${className}`}>
            <div className="card-body">
              <div className="row">
                <></>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <h4 className="card-label font-weight-bolder text-dark mb-6">
                      {fileGroupByDisplayName != ""
                        ? fileGroupByDisplayName
                        : groupByColumn}
                    </h4>
                    <div className="checkbox-block">
                      {categories.map((category, key) => {
                        const isChecked = getChecked(category);

                        const hasSelectedProduct = !isChecked
                          ? getHasSelected(category)
                          : false;

                        return (
                          <div
                            className="checkbox-inline"
                            key={key + "_" + category}
                          >
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                name={category}
                                onChange={(e) => {
                                  handleCategoryCheck(category, isChecked);
                                  onCategoryClicked(category);
                                }}
                                checked={linkLabel === "yes" ? true : isChecked}
                                disabled={linkLabel === "yes" || isSingle}
                              />
                              <span
                                className={
                                  hasSelectedProduct
                                    ? "mr-0 has-product-selected"
                                    : "mr-0"
                                }
                              >
                                {hasSelectedProduct ? "-" : ""}
                              </span>
                              <div
                                style={
                                  selectedCategory === category
                                    ? { color: "#E57F0E" }
                                    : {}
                                }
                                className="pl-3 font-weight-bolder"
                              >
                                {category}
                              </div>
                            </label>
                            <div
                              className="arrowright"
                              onClick={onCategoryClicked.bind(this, category)}
                            ></div>
                          </div>
                        );
                      })}

                      {/* Select All Checkbox */}
                      {categories != undefined &&
                        !isSingle &&
                        nodeDataLength > 0 && (
                          <>
                            <div>
                              <hr />
                            </div>
                            <div className="checkbox-inline">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  onChange={handleCheckAll}
                                  name="Select-All"
                                  checked={
                                    nodeDataLength > 0 &&
                                    allProducts?.length == selectProduct?.length
                                      ? true
                                      : false
                                  }
                                />

                                <span className=""></span>
                                <div className=" font-weight-bolder">
                                  Select All
                                </div>
                              </label>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                {!!products?.length && (
                  <div className="col-md-12 col-lg-6">
                    <div className="form-group">
                      <h4 className="card-label font-weight-bolder text-dark mb-6">
                        {fileDisplayColumn != ""
                          ? fileDisplayColumn
                          : columnName}
                      </h4>
                      <div className="checkbox-block">
                        {products.map((prd, pId) => {
                          const isChecked = !!selectProduct?.find(
                            (s_prd) => s_prd?.[columnName] === prd?.[columnName]
                          );
                          return (
                            <label
                              className="checkbox mb-3"
                              key={prd?.[columnName] + "_" + pId}
                            >
                              <input
                                onChange={handleProductCheck.bind(
                                  this,
                                  isChecked,
                                  prd
                                )}
                                type="checkbox"
                                checked={linkLabel === "yes" ? true : isChecked}
                                disabled={linkLabel === "yes"}
                                name={prd?.[columnName]}
                              />
                              <span></span>
                              <div className="pl-3 font-weight-bolder">
                                {prd?.[columnName]}
                              </div>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MultiOptionWithGroupBy;
