import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import * as USER_SERVICES from "../../../../_service/users/user.service";

export const actionTypes = {
  ADD: "[ADD USER] Action",
  DELETE: "[DELETE USER] Action",
  LIST: "[LIST USER] Action",
  LOADING: "[LOADING USER] Action",
  ERROR: "[ERROR USER] Action",
  GET_USERS_LIST: "[GET_USERS_LIST] Action",
  FETCH_LOGGEDIN_TRAVERSE_QUESTIONS: "[FETCH_LOGGEDIN_TRAVERSE_QUESTIONS] Action",
  SET_USER_TRAVERSE_QUESTIONS: "[SET_USER_TRAVERSE_QUESTIONS] Action",
  CHANGE_PASSWORD_ACTION: "[CHANGE_PASSWORD] Action",
};

const initialAuthState = {
  data: [],
  questions: [],
  isLoading: false,
  error: "",
};

export const reducer = persistReducer(
  { storage, key: "user" },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.LIST: {
        const { users } = action.payload;

        return { ...state, data: users };
      }

      case actionTypes.SET_USER_TRAVERSE_QUESTIONS: {
        const { questions } = action.payload;

        return { ...state, questions };
      }

      case actionTypes.LOADING: {
        const { isLoading } = action.payload;

        return { ...state, isLoading };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;

        return { ...state, error };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  add: (user) => ({ type: actionTypes.ADD, payload: { user } }),
  changePassword: (passwordDetails) => ({
    type: actionTypes.CHANGE_PASSWORD_ACTION,
    payload: { passwordDetails },
  }),
  delete: (id) => ({ type: actionTypes.DELETE, payload: { id } }),
  list: (users) => ({ type: actionTypes.LIST, payload: { users } }),
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING,
    payload: { isLoading },
  }),
  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),
  getUsersList: () => ({ type: actionTypes.GET_USERS_LIST }),
  fetchUserTraverseQuestions: (id) => ({
    type: actionTypes.FETCH_LOGGEDIN_TRAVERSE_QUESTIONS,
    payload: { id },
  }),
  setUserTraverseQuestions: (questions) => ({
    type: actionTypes.SET_USER_TRAVERSE_QUESTIONS,
    payload: { questions },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.ADD, function* addSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { user } = action.payload;
      if (user?.id) {
        yield call(USER_SERVICES.updateUser, user);
      } else {
        yield call(USER_SERVICES.addUser, { ...user, id: undefined });
      }
      const response = yield call(USER_SERVICES.getUsers);
      yield put(actions.list(response.data?.data?.docs));
    } catch (error) {
      yield put(actions.error("Failed user add!"));
    }
    yield put(actions.isLoading(false));
  });
  yield takeLatest(
    actionTypes.CHANGE_PASSWORD_ACTION,
    function* changePasswordSaga(action) {
      yield put(actions.isLoading(true));
      try {
        const { passwordDetails } = action.payload;
        yield call(USER_SERVICES.changePassword, passwordDetails);
      } catch (error) {
        yield put(actions.error("Failed user add!"));
      }
      yield put(actions.isLoading(false));
    }
  );

  yield takeLatest(actionTypes.DELETE, function* deleteSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { id } = action.payload;
      yield call(USER_SERVICES.deleteUser, id);
      const response = yield call(USER_SERVICES.getUsers);
      yield put(actions.list(response.data?.data?.docs));
    } catch (error) {
      yield put(actions.error("Failed user delete!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.GET_USERS_LIST, function* listSaga() {
    yield put(actions.isLoading(true));
    try {
      const response = yield call(USER_SERVICES.getUsers);
      yield put(actions.list(response.data?.data?.docs));
    } catch (error) {
      yield put(actions.error("Failed topic fetch!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(
    actionTypes.FETCH_LOGGEDIN_TRAVERSE_QUESTIONS,
    function* fetchUserTraverseQuestionsSaga(action) {
      yield put(actions.isLoading(true));
      try {
        const { id } = action.payload;
        const response = yield call(USER_SERVICES.getUserTraverse, id);
        yield put(actions.setUserTraverseQuestions(response.data?.data));
      } catch (error) {
        yield put(actions.error("Failed question fetch!"));
      }
      yield put(actions.isLoading(false));
    }
  );
}
