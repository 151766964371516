import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RingLoader } from "react-spinners";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import MultiOptionWithGroupBy from "./MultiOptionWithGroupBy";
import { setToast } from "../../../../app/modules/toast";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import { traversalSectionHeight } from "../../../_utils/constants";

export function MultiOptionSelect({ nodeData, transactionId, questionId }) {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState([]);
  const [reTraverseData, setReTraverseData] = useState([]);
  const [productList, setProductList] = useState([]);
  const [displayColumnName, setDisplayColumnName] = useState("");
  const [displayColumnForObject, setDisplayColumnForObject] = useState("");

  const nodeIsLoading = useSelector((store) => store.webTravers.nodeIsLoading);
  const retraversalData = useSelector(
    (store) => store.webTravers.retraversalData
  );

  useEffect(() => {
    console.log(nodeData?.data);
    let displayColumnForObject = "";
    if (nodeData?.data.length > 0) {
      let option = nodeData?.data[0];

      Object.keys(option).forEach((optionKeys) => {
        if (
          optionKeys.toLowerCase() ==
          nodeData?.metaData?.displayColumnName.split(" ")[0].toLowerCase()
        ) {
          displayColumnForObject = optionKeys;
        }
      });
      setDisplayColumnForObject(displayColumnForObject);
    }

    let useVariable =
      nodeData?.metaData?.singleVariableName != ""
        ? nodeData?.metaData?.singleVariableName
        : nodeData?.metaData?.localVariableName;
    let selectedData = retraversalData.find(
      (item) => Object.keys(item)[0] == useVariable
    );

    if (selectedData != undefined) {
      if (
        nodeData?.metaData?.groupByColumn !== "" &&
        nodeData?.metaData?.groupByColumn !== undefined
      ) {
        setReTraverseData(selectedData[useVariable]);
      } else {
        let selectedIds = [];
        selectedData[useVariable].map((item) => {
          selectedIds.push({
            [displayColumnForObject]: item[displayColumnForObject],
          });
        });

        setSelectedValue(selectedIds);
      }
    }
  }, [retraversalData]);
  const handleFieldChange = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    setDisplayColumnName(nodeData?.metaData?.displayColumnName.split(" ")[0]);
    if (nodeData?.data.length > 0) {
      let option = nodeData?.data[0];
      if (nodeData?.metaData?.groupByColumn !== "") {
        setProductList(nodeData?.data);
        option = nodeData?.data[0].list[0];
      }

      let displayColumnForObject = "";
      Object.keys(option).forEach((optionKeys) => {
        if (
          optionKeys.toLowerCase() ==
          nodeData?.metaData?.displayColumnName.split(" ")[0].toLowerCase()
        ) {
          displayColumnForObject = optionKeys;
        }
      });
      setDisplayColumnForObject(displayColumnForObject);
    }
  }, []);

  // localVariableName

  const handleValue = (event) => {
    let oldSelectedValue = [...selectedValue];
    if (nodeData.metaData.isSingle) {
      if (event.target.checked) {
        oldSelectedValue = [{ [displayColumnForObject]: event.target.value }];
      } else {
        oldSelectedValue = [];
      }
    } else {
      if (event.target.checked) {
        oldSelectedValue.push({ [displayColumnForObject]: event.target.value });
      } else {
        let index = oldSelectedValue.findIndex(
          (item) => item[displayColumnForObject] == event.target.value
        );
        if (index > -1) {
          oldSelectedValue.splice(index, 1);
        }
      }
    }

    setSelectedValue(oldSelectedValue);
  };

  // Select All Data
  const handleSelectAll = (e) => {
    let valueArray = [];
    if (e.target.checked) {
      nodeData.data.map((value) => {
        valueArray.push({
          [displayColumnForObject]: value[displayColumnForObject],
        });
      });
    } else {
      valueArray = [];
    }
    setSelectedValue(valueArray);
  };

  const handleConfirm = () => {
    if (selectedValue.length > 0) {
      let data = {
        fromLink: nodeData.fromLink,
        toNode: nodeData._id,
        transactionId: transactionId,
        nextNodeLinkId: nodeData.links[0]._id,
        questionId: questionId,
        metaData: {
          variableName: nodeData.metaData.localVariableName,
          variableId: nodeData.metaData.variableId,
          value: selectedValue,
          isFetchFromLookup: nodeData.metaData.isFetchFromLookup,
        },
      };

      dispatch(WEB_TRAVERS.saveNodeDataApi(data));
    } else {
      setToast("Please select option", "error");
    }
  };
  const [showContinueButtonAbove, setShowContinueButtonAbove] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref != null) {
      setShowContinueButtonAbove(
        ref.current.clientHeight > traversalSectionHeight
      );
    }
  });

  return (
    <>
      {nodeIsLoading ? (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      ) : (
        <div className={`card card-custom `}>
          <div className="card-body" ref={ref}>
            <div className="card-header">
              <h3 className="card-title flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  {nodeData?.metaData?.variableLabel}
                </span>
              </h3>
              {showContinueButtonAbove && (
                <TraversalFooter
                  clickAble={!(selectedValue.length > 0)}
                  nextNode={() => handleConfirm()}
                />
              )}
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  {nodeData?.metaData?.groupByColumn !== "" &&
                  nodeData?.metaData?.groupByColumn !== undefined ? (
                    <>
                      <div className="traversal__product__block">
                        <MultiOptionWithGroupBy
                          conditionNodeDetails={productList}
                          groupByColumn={nodeData?.metaData?.groupByColumn}
                          columnName={displayColumnName}
                          linkLabel={"no"}
                          optionNodeType={nodeData?.type}
                          displayColumnForObject={displayColumnForObject}
                          selectedValue={selectedValue}
                          isSingle={nodeData?.metaData?.isSingle}
                          reTraverseData={reTraverseData}
                          onFieldChange={handleFieldChange}
                          nodeDataLength={nodeData?.data.length}
                          fileGroupByDisplayName={
                            nodeData?.metaData?.fileGroupByDisplayName
                          }
                          fileDisplayColumn={
                            nodeData?.metaData?.fileDisplayColumn
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <div className="checkbox-block">
                      {/* // Select All CheckBox */}
                      {nodeData?.data != undefined && (
                        <>
                          <div className="checkbox-inline">
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                onChange={(e) => handleSelectAll(e)}
                                name="Select All"
                                checked={
                                  nodeData.data.length > 0 &&
                                  nodeData.data.length == selectedValue.length
                                    ? true
                                    : false
                                }
                              />

                              <span className=""></span>
                              <div
                                style={
                                  nodeData?.data?.length == selectedValue.length
                                    ? { color: "#E57F0E" }
                                    : {}
                                }
                                className="pl-3 font-weight-bolder"
                              >
                                Select All
                              </div>
                            </label>
                          </div>
                          <div>
                            <hr />
                          </div>
                        </>
                      )}
                      {nodeData?.data != undefined &&
                        [...new Set(nodeData?.data)].map((option, key) => {
                          return (
                            <div
                              className="checkbox-inline"
                              key={
                                option?.[displayColumnForObject] +
                                "_" +
                                Math.floor(Math.random() * 10000 + 1)
                              }
                            >
                              <label className="checkbox">
                                <input
                                  type={"checkbox"}
                                  name={option?.[displayColumnForObject]}
                                  value={option?.[displayColumnForObject]}
                                  onChange={(e) => handleValue(e)}
                                  checked={
                                    selectedValue.findIndex(
                                      (item) =>
                                        item[displayColumnForObject] ==
                                        option?.[displayColumnForObject]
                                    ) > -1
                                  }
                                />
                                <span className=""></span>
                                <div
                                  style={
                                    selectedValue.findIndex(
                                      (item) =>
                                        item[displayColumnForObject] ==
                                        option?.[displayColumnForObject]
                                    ) > -1
                                      ? { color: "#E57F0E" }
                                      : {}
                                  }
                                  className="pl-3 font-weight-bolder"
                                >
                                  {option?.[displayColumnForObject]}
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <TraversalFooter
              clickAble={!(selectedValue.length > 0)}
              nextNode={() => handleConfirm()}
            />
          </div>
        </div>
      )}
    </>
  );
}
