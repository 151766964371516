import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import RingLoader from "react-spinners/RingLoader";
import _unionBy from "lodash/unionBy";
import MultiOption from "./MultiOptionWithGroupBy";
import { setToast } from "../../../../app/modules/toast";
import { TraversalRadioButton } from "../TraversalRadioButton/TraversalRadioButton";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import "../Traversal.scss";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import { StateMap } from "../StateMap/StateMap";
import { traversalSectionHeight } from "../../../_utils/constants";

export function PassData({ nodeData, transactionId, questionId }) {
  const dispatch = useDispatch();
  const nodeIsLoading = useSelector((store) => store.webTravers.dataIsLoading);
  const conditionNodeDetails = useSelector(
    (store) => store.webTravers.conditionNodeDetails
  );
  const [selectedLink, setSelectedLink] = useState({});
  const [optionSelection, setOptionSelection] = useState("");
  // Selected Product
  const [selectedValue, setSelectedValue] = useState([]);
  const [reTraverseData, setReTraverseData] = useState([]);
  const [optionData, setOptionData] = useState([]);

  const [linkLabel, setLinkLabel] = useState("");
  const [variableId, setVariableId] = useState("");
  // Selected State
  const [state, setState] = useState([]);
  const [retraverseState, setRetraverseState] = useState([]);
  const [manualSelectedStates, setManualSelectedStates] = React.useState([]);
  const [manualUnselectedStates, setManualUnselectedStates] = React.useState(
    []
  );
  const [displayColumnForObject, setDisplayColumnForObject] = useState("");
  const [showOption, setshowOption] = useState("");
  const retraversalData = useSelector(
    (store) => store.webTravers.retraversalData
  );
  const [displayColumnName, setDisplayColumnName] = useState("");

  useEffect(() => {
    let productSelectedData = retraversalData.find(
      (item) => Object.keys(item)[0] == showOption
    );
    if (productSelectedData != undefined) {
      setReTraverseData(productSelectedData?.[showOption]);
    }
  }, [showOption]);

  const handleFieldChange = (value) => {
    setSelectedValue(value);
  };
  useEffect(() => {
    setOptionSelection(nodeData.links[0]._id);
    setOptionData(nodeData?.data);
    setshowOption(nodeData?.metaData?.localVariableName);
    setDisplayColumnName(nodeData?.metaData?.displayColumnName);
    setVariableId(nodeData?.metaData?.variableId);
    setLinkLabel("yes");
    let option = nodeData?.data[0];
    if (nodeData?.metaData?.groupByColumn !== "") {
      option = nodeData?.data[0].list[0];
    }

    let displayColumnForObject = "";
    Object.keys(option).forEach((optionKeys) => {
      if (
        optionKeys.toLowerCase() ==
        nodeData?.metaData?.displayColumnName.split(" ")[0].toLowerCase()
      ) {
        displayColumnForObject = optionKeys;
      }
    });
    setDisplayColumnForObject(displayColumnForObject);
  }, [nodeData]);

  const handleStateChange = (states, appliedFilters) => {
    if (appliedFilters) {
      states = _unionBy(states, manualSelectedStates, "name");
    }
    setState(states);
  };

  const handleConfirm = () => {
    let data = {
      fromLink: nodeData.fromLink,
      toNode: nodeData._id,
      transactionId: transactionId,
      nextNodeLinkId: optionSelection,
      questionId: questionId,
    };
    if (showOption === "States") {
      data.metaData = {
        variableId: variableId,
        isFetchFromLookup: nodeData?.metaData?.isFetchFromLookup,
        variableName: showOption,
        value: state.map(function(el) {
          return el._id;
        }),
      };
    } else {
      data.metaData = {
        variableId: variableId,
        isFetchFromLookup: nodeData?.metaData?.isFetchFromLookup,
        variableName: showOption,
        value: selectedValue,
      };
    }

    dispatch(WEB_TRAVERS.saveNodeDataApi(data));
  };

  const handleSelection = (value) => {
    setOptionSelection(value);
    let option = conditionNodeDetails.find((data) => data.fromLink == value);
    setDisplayColumnName(option?.metaData?.displayColumnName);
    setSelectedLink(option);
    setOptionData(option?.data);
    setshowOption(option?.metaData?.localVariableName);
    setVariableId(option?.variableId);
    setLinkLabel(option?.linkLabel.toLowerCase());
  };
  const [showContinueButtonAbove, setShowContinueButtonAbove] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref != null) {
      setShowContinueButtonAbove(
        ref.current.clientHeight > traversalSectionHeight
      );
    }
  });
  return (
    <>
      <div className="card-body" ref={ref}>
        <div className="card-header">
          <h3 className="card-title flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {nodeData?.metaData?.variableLabel}
            </span>
          </h3>
          {showContinueButtonAbove && (
            <TraversalFooter nextNode={() => handleConfirm()} />
          )}
        </div>
        {showOption === "States" && (
          <>
            <div className="traversal__map__block">
              <StateMap
                optionSelection={linkLabel}
                nodeState={optionData}
                retraverseState={reTraverseData}
                state={state}
                onStateChange={handleStateChange}
                manualSelectedStates={manualSelectedStates}
                setManualSelectedStates={setManualSelectedStates}
                manualUnselectedStates={manualUnselectedStates}
                setManualUnselectedStates={setManualUnselectedStates}
                groupByColumn={displayColumnName.split(" ")[1]}
                columnName={displayColumnName.split(" ")[0]}
              />
            </div>
          </>
        )}
        {showOption !== "States" && (
          <>
            <div className="traversal__product__block">
              <MultiOption
                groupByColumn={displayColumnName.split(" ")[1]}
                columnName={displayColumnName.split(" ")[0]}
                conditionNodeDetails={optionData}
                selectedOptionFromConditionalNode={optionSelection}
                reTraverseData={reTraverseData}
                linkLabel={linkLabel}
                selectedValue={selectedValue}
                onFieldChange={handleFieldChange}
                displayColumnForObject={displayColumnForObject}
                fileGroupByDisplayName={
                  nodeData?.metaData?.fileGroupByDisplayName
                }
                fileDisplayColumn={nodeData?.metaData?.fileDisplayColumn}
              />
            </div>
          </>
        )}
      </div>
      <div className="card-footer">
        <TraversalFooter
          clickAble={optionSelection === ""}
          nextNode={() => handleConfirm()}
        />
      </div>
    </>
  );
}
