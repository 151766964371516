export const formValidations = {
  usersForm: {
    firstName: "First name is required.",
    invalidFirstName: "First name should be valid string.",
    lastName: "Last name is required.",
    invalidLastName: "Last name should be valid string.",
    email: "Email is required.",
    invalidEmail: "Invalid Email.",
    phone: "Phone Number is required.",
    invalidPhone: "Invalid Phone Number.",
    designation: "Designation is required.",
    password: "Password is required.",
    invalidDesignation: "Designation should have more than 3 letters.",
    invalidDesignationString:
      "Designation should be start with string and only accepts letters, digits and spaces",
    countryCode: "Country code is required.",
    userRole: "User role is required",
    minPassword: "Password must be greater than 8 characters",
    maxPassword: "Password must be less than or equal to 64 characters",
    organizationId: "Organization is required.",
  },
  organizationsForm: {
    companyName: "Company name is required.",
    geographicScope: "Grographic scope is required.",
    city: "City is required.",
    state: "State is required.",
    productSold: "Product sold is required.",
    salesChannelUtilized: "Sales channel utilized is required.",
  },
  diagramForm: {
    question: "Question is required",
    variableName: "Variable Name is required.",
    invalidVariableName:
      "Variable name should be valid with Character, Number and '_'.",
  },
  questionForm: {
    text: "Tool Name is required",
    validText: "Enter Valid Tool Name",
    assumptions: "Assumptions is required",
    screen_tagline: "Screen Tagline is required",
    description: "Description is required",
    summery_report: "Summary Report is required",
    icon_logo: "Logo is required",
  },
};

export const traversalSectionHeight = 480;
