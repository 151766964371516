import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RingLoader } from "react-spinners";
import "./StartTravesal.scss";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
export function StartTravesal({
  nodeData,
  questionId,
  questionDetails,
  transactionId,
}) {
  useEffect(() => {
    if (transactionId != "" && nodeData.type == "Start") {
      dispatch(WEB_TRAVERS.nodeIsLoading(true));
      dispatch(
        WEB_TRAVERS.setRetraversalDataApi({
          questionId: questionId,
          transactionId: transactionId,
        })
      );
    }
  }, [transactionId]);
  const dispatch = useDispatch();
  return (
    <div className="StartTravesal__main">
      {nodeData.type == "Start" && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      {nodeData.type != "Start" && (
        <div className="card-body">
          <div className="text-center">
            <h3 className="font-weight-bolder text-dark mb-8 font-italic">
              “{questionDetails.screen_tagline}”
            </h3>
            <h4 className="mb-6">
              <span className="start__text font-weight-bolder">Let’s Go</span>
            </h4>
            <button
              onClick={() =>
                dispatch(WEB_TRAVERS.startTraversalApi(questionId))
              }
              className="btn btn-primary btn__start"
            >
              START
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
