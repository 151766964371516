import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import * as QUESTION_SERVICES from "../../../../_service/questions/questions.service";

export const actionTypes = {
  ADD_QUESTION: "[ADD_QUESTION] Action",
  UPDATE_QUESTION: "[UPDATE_QUESTION] Action",
  DELETE_QUESTION: "[DELETE_QUESTION] Action",
  LIST_QUESTION: "[LIST_QUESTION] Action",
  LOADING_QUESTION: "[LOADING_QUESTION] Action",
  ERROR_QUESTION: "[ERROR_QUESTION] Action",
  GET_SUBTOPIC_DETAILS: "[GET_SUBTOPIC_DETAILS] Action",
  FETCH_SUBTOPIC_DETAILS: "[FETCH_SUBTOPIC_DETAILS] Action",
  QUESTION_DETAILS:"[QUESTION_DETAILS] Action",
  QUESTION_REDIRECT:"[QUESTION_REDIRECT] Action"
};

const initialState = {
  subtopicDetails: {
    questions: [],
  },
  isLoading: false,
  questionDetails:{},
  error: "",
  redirect:false
};

export const reducer = persistReducer(
  { storage, key: "question" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_SUBTOPIC_DETAILS: {
        const { subtopicDetails } = action.payload;

        return { ...state, subtopicDetails };
      }

      case actionTypes.LOADING_QUESTION: {
        const { isLoading } = action.payload;

        return { ...state, isLoading };
      }

      case actionTypes.ERROR_QUESTION: {
        const { error } = action.payload;

        return { ...state, error };
      }
      case actionTypes.QUESTION_REDIRECT: {
        const { redirect } = action.payload;

        return { ...state, redirect };
      }
      case actionTypes.QUESTION_DETAILS: {
        const { questionDetails } = action.payload;

        return { ...state, questionDetails };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  addQuestion: (question, parentSubtopic) => ({
    type: actionTypes.ADD_QUESTION,
    payload: { question, parentSubtopic },
  }),
  updateQuestion: (id, parentSubtopic, question) => ({
    type: actionTypes.UPDATE_QUESTION,
    payload: { id, parentSubtopic, question },
  }),
  deleteQuestion: (id, parentSubtopic) => ({
    type: actionTypes.DELETE_QUESTION,
    payload: { id, parentSubtopic },
  }),
  getSubtopicDetails: (parentSubtopic) => ({
    type: actionTypes.GET_SUBTOPIC_DETAILS,
    payload: { parentSubtopic },
  }),
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING_QUESTION,
    payload: { isLoading },
  }),
  isRedirect: (redirect) => ({
    type: actionTypes.QUESTION_REDIRECT,
    payload: { redirect },
  }),
  questionDetails: (questionDetails) => ({
    type: actionTypes.QUESTION_DETAILS,
    payload: { questionDetails },
  }),
  error: (error) => ({ type: actionTypes.ERROR_QUESTION, payload: { error } }),
  fetchSubtopicDetails: (subtopicDetails) => ({
    type: actionTypes.FETCH_SUBTOPIC_DETAILS,
    payload: { subtopicDetails },
  }),
};

export function* saga() {
  
  yield takeLatest(actionTypes.ADD_QUESTION, function* addSubtopicSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { question } = action.payload;
      if (question?.id) {
        yield call(QUESTION_SERVICES.updateQuestion, question?.id, question);
      } else {
        yield call(QUESTION_SERVICES.addQuestion, question);
      }
      
      const response = yield call(
        QUESTION_SERVICES.getSubtopicDetails,
        question.topicId
      );
      const [subtopicDetails] = response.data?.data;
      yield put(
        actions.fetchSubtopicDetails(
          subtopicDetails || initialState.subtopicDetails
        )
      );

      let questionNewDetails = subtopicDetails.questions.find((item) => item.text === question.text)
      if(questionNewDetails !== undefined){
       yield put(actions.questionDetails(questionNewDetails)) 
      }
      yield put(actions.isRedirect(true))
    } catch (error) {
      yield put(actions.error("Failed Question add!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.UPDATE_QUESTION, function* updateSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { id, parentSubtopic, question } = action.payload;
      yield call(QUESTION_SERVICES.updateQuestion, id, question);
      const response = yield call(
        QUESTION_SERVICES.getSubtopicDetails,
        parentSubtopic
      );
      const [subtopicDetails] = response.data?.data;
      yield put(
        actions.fetchSubtopicDetails(
          subtopicDetails || initialState.subtopicDetails
        )
      );
    } catch (error) {
      yield put(actions.error("Failed topic update!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.DELETE_QUESTION, function* deleteSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { id, parentSubtopic } = action.payload;
      yield call(QUESTION_SERVICES.deleteQuestion, id);
      const response = yield call(
        QUESTION_SERVICES.getSubtopicDetails,
        parentSubtopic
      );
      const [subtopicDetails] = response.data?.data;
      yield put(
        actions.fetchSubtopicDetails(
          subtopicDetails || initialState.subtopicDetails
        )
      );
      yield put(actions.isRedirect(true))
    } catch (error) {
      yield put(actions.error("Failed topic delete!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(
    actionTypes.GET_SUBTOPIC_DETAILS,
    function* getSubtopicDetailsSaga(action) {
      yield put(actions.isLoading(true));
      try {
        const { parentSubtopic } = action.payload;
        const response = yield call(
          QUESTION_SERVICES.getSubtopicDetails,
          parentSubtopic
        );
        const [subtopicDetails] = response.data?.data;
        yield put(
          actions.fetchSubtopicDetails(
            subtopicDetails || initialState.subtopicDetails
          )
        );
      } catch (error) {
        yield put(actions.error("Failed topic fetch!"));
      }
      yield put(actions.isLoading(false));
    }
  );
}
