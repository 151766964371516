import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const createDiagram = (diagramData) => {
  // const diagramCreateApi = getUrl("addDiagram");
  // return post(diagramCreateApi, diagramData, getUserToken());
  return Services({
    url: "/admin/decisionTree",
    method: "POST",
    data: diagramData,
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success", 'top-center');
      return response;
    })
    .catch((error) => {
      // error?.response?.data?.message &&
      //   setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};
export const updateDiagram = (diagramData) => {
  // const diagramCreateApi = getUrl("addDiagram");
  // return post(diagramCreateApi, diagramData, getUserToken());
  return Services({
    url: "/admin/decisionTree",
    method: "PUT",
    data: diagramData,
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success", 'top-center');
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};

export const deleteDiagram = (id) =>
  Services({
    url: `/admin/decisionTree/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success", 'top-center');
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error", 'top-center');
      return Promise.reject(error);
    });
export const getDiagram = (questionId) => {
  // const diagramCreateApi = getUrl("addDiagram");
  // return post(diagramCreateApi, diagramData, getUserToken());
  return Services({
    url: "/admin/decisionTree/"+ questionId,
    method: "GET"
    
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};
export const getPredefinedLogicDiagram = (predefinedogicId) => {
  // const diagramCreateApi = getUrl("addDiagram");
  // return post(diagramCreateApi, diagramData, getUserToken());
  return Services({
    url: "/admin/decisionTree/process/"+ predefinedogicId,
    method: "GET"
    
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message,'success');
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};
export const getPredefinedLogicList = (questionId) => {
  
  return Services({
    url: "admin/excel-data/preDefinedLogics",
    method: "GET"
    
  })
    .then((response) => {
      // response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};
export const getPredefinedLogicVariables = (predefinedLogic) => {
  return Services({
    url: "/admin/variable/list/"+predefinedLogic,
    method: "GET"
    
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
  
};
export const getVariableList = (nodeType) => {
  return Services({
    url: "/admin/variable?nodeType="+nodeType,
    method: "GET"
    
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
  
};
export const getOperatorList = (nodeType) => {
  return Services({
    url: "/admin//variable/operators?nodeType="+nodeType,
    method: "GET"
    
  })
    .then((response) => {
      
      response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
  
};
