import React, { useEffect , useRef , useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import RingLoader from "react-spinners/RingLoader";
import { actions as HELP_ACTIONS } from "../../../app/modules/HelpSection/_redux/HelpSectionRedux";
import LoadPdf from "./LoadPdf";
import "./help.css"

export default function HelpIcon({ pageName, isToolBox = false , questionId}) {
  const [openHelpModal, setOpenHelpModal] = React.useState(false);
  const dispatch = useDispatch();
  const singlePageDetails = useSelector(
    (store) => store.helpSection.singlePageDetails
  );
  const isLoading = useSelector((store) => store.helpSection.isLoading);
  useEffect(() => {
    if (pageName != "" && openHelpModal) {
      console.log(isToolBox);
      dispatch(HELP_ACTIONS.getsinglePageData(pageName, isToolBox));
    }
  }, [pageName, openHelpModal]);

  return (
    <>
      <div
        className= {questionId ? "helpicon_right" : "helpicon"}
        onClick={() => {
          setOpenHelpModal(true);
        }}
      ></div>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openHelpModal}
        onHide={() => {
          setOpenHelpModal(false);
        }}
      >
        <Modal.Body>
          <h3 className="mt-2 pb-6">Help for {singlePageDetails?.label}</h3>
          {isLoading ? (
            <div className="loader_wrapper">
              <RingLoader color={"darkcyan"} loading={true} size={40} />
            </div>
          ) : singlePageDetails?.fileUrl != "" ? (
            <LoadPdf pdfUrl={singlePageDetails?.fileUrl} />
          ) : (
            <label className="justify-content-center">
              <h3>No Data Found</h3>{" "}
            </label>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center pt-0 mb-2">
          <Button
            className="w-100px btn-primary"
            onClick={() => {
              setOpenHelpModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
