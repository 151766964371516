import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import RingLoader from "react-spinners/RingLoader";
import _unionBy from "lodash/unionBy";
import MultiOptionWithGroupBy from "./MultiOptionWithGroupBy";
import MultiOption from "./MultiOption";
import { setToast } from "../../../../app/modules/toast";
import { TraversalRadioButton } from "../TraversalRadioButton/TraversalRadioButton";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import "../Traversal.scss";
import { traversalSectionHeight } from "../../../_utils/constants";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import { StateMap } from "../StateMap/StateMap";

export function ConditionalNode({ nodeData, transactionId, questionId }) {
  const dispatch = useDispatch();
  const nodeIsLoading = useSelector((store) => store.webTravers.dataIsLoading);
  const conditionNodeDetails = useSelector(
    (store) => store.webTravers.conditionNodeDetails
  );
  const [selectedLink, setSelectedLink] = useState({});
  const [optionSelection, setOptionSelection] = useState("");
  // Selected Product
  const [selectedValue, setSelectedValue] = useState([]);
  const [reTraverseData, setReTraverseData] = useState([]);
  const [optionData, setOptionData] = useState([]);
  const [displayColumnForObject, setDisplayColumnForObject] = useState("");
  const [linkLabel, setLinkLabel] = useState("");
  const [variableId, setVariableId] = useState("");
  // Selected State
  const [state, setState] = useState([]);
  const [retraverseState, setRetraverseState] = useState([]);
  const [manualSelectedStates, setManualSelectedStates] = React.useState([]);
  const [manualUnselectedStates, setManualUnselectedStates] = React.useState(
    []
  );
  const [showOption, setshowOption] = useState("");
  const retraversalData = useSelector(
    (store) => store.webTravers.retraversalData
  );
  const [displayColumnName, setDisplayColumnName] = useState("");
  const [columnName, setColumnName] = useState("");

  useEffect(() => {
    let productSelectedData = retraversalData.find(
      (item) => Object.keys(item)[0] == showOption
    );
    if (productSelectedData != undefined) {
      setReTraverseData(productSelectedData?.[showOption]);
    }
  }, [showOption]);

  const handleFieldChange = (value) => {
    setSelectedValue(value);
  };
  useEffect(() => {
    dispatch(
      WEB_TRAVERS.getNodeDetailsApi({
        decisionNodeId: nodeData._id,
        transactionId,
      })
    );
  }, []);

  const handleStateChange = (states, appliedFilters) => {
    if (appliedFilters) {
      states = _unionBy(states, manualSelectedStates, "name");
    }
    setState(states);
  };

  const handleConfirm = () => {
    let data = {
      fromLink: nodeData.fromLink,
      toNode: nodeData._id,
      transactionId: transactionId,
      nextNodeLinkId: optionSelection,
      questionId: questionId,
    };
    if (conditionNodeDetails.length === 0) {
      dispatch(WEB_TRAVERS.saveNodeDataApi(data));
    } else {
      data.nextLinkSelection = selectedLink;

      if (showOption === "States") {
        data.metaDetails = {
          variableId: variableId,
          variableName: showOption,
          isFetchFromLookup: selectedLink.metaData.isFetchFromLookup,
          value: state.map(function(el) {
            return el._id;
          }),
        };
      } else {
        data.metaDetails = {
          variableId: variableId,
          variableName: showOption,
          value: selectedValue,
          isFetchFromLookup: selectedLink.metaData.isFetchFromLookup,
        };
      }

      if (data.metaDetails.value.length > 0) {
        dispatch(WEB_TRAVERS.saveConditionalNodeDataApi(data));
      } else {
        setToast("Please select option", "error");
      }
    }
  };
  const handleSelection = (value) => {
    setOptionSelection(value);
    let option = conditionNodeDetails.find((data) => data.fromLink == value);

    setDisplayColumnName(option?.metaData?.displayColumnName);
    setColumnName(option?.metaData?.displayColumnName.split(" ")[0]);
    setSelectedLink(option);
    setOptionData(option?.data);

    if (option?.metaData?.localVariableName === "States") {
      let nodeStateList = option?.data.map((item) => {
        return item.list != undefined ? item.list : item;
      });
      let nodeStateListGroup = [].concat(...nodeStateList);
      let preSelectState = nodeStateListGroup.reduce((result, _nodeState) => {
        if (_nodeState?.isSelected) {
          result = [...result, _nodeState];
        }
        return result;
      }, []);
      setManualSelectedStates(preSelectState);
    }

    setshowOption(option?.metaData?.localVariableName);
    setVariableId(option?.variableId);
    setLinkLabel(option?.linkLabel.toLowerCase());
    if (option != undefined) {
      let optionData = option?.data[0];
      if (option?.metaData?.groupByColumn !== "") {
        optionData = option?.data[0].list[0];
      }

      let displayColumnForObject = "";
      Object.keys(optionData).forEach((optionKeys) => {
        if (
          optionKeys.toLowerCase() ==
          option?.metaData?.displayColumnName.split(" ")[0].toLowerCase()
        ) {
          displayColumnForObject = optionKeys;
        }
      });
      setDisplayColumnForObject(displayColumnForObject);
    }
  };

  const handleValue = (event) => {
    let oldSelectedValue = [...selectedValue];
    if (nodeData.metaData.isSingle) {
      if (event.target.checked) {
        oldSelectedValue = [event.target.value];
      } else {
        oldSelectedValue = [];
      }
    } else {
      if (event.target.checked) {
        oldSelectedValue.push(event.target.value);
      } else {
        let index = oldSelectedValue.indexOf(event.target.value);
        if (index > -1) {
          oldSelectedValue.splice(index, 1);
        }
      }
    }
    setSelectedValue(oldSelectedValue);
  };
  const [showContinueButtonAbove, setShowContinueButtonAbove] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref != null) {
      setShowContinueButtonAbove(
        ref.current.clientHeight > traversalSectionHeight
      );
    }
  });

  return (
    <>
      <div className="card-body" ref={ref}>
        <div className="card-header">
          <h3 className="card-title flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {nodeData.metaData.label}
            </span>
          </h3>
          {showContinueButtonAbove && (
            <TraversalFooter
              clickAble={optionSelection === ""}
              nextNode={() => handleConfirm()}
            />
          )}
        </div>
        {!nodeIsLoading && (
          <TraversalRadioButton
            dataForOption={nodeData.links.sort((linkA, linkB) =>
              linkB.label.localeCompare(linkA.label)
            )}
            onSelect={(value) => handleSelection(value)}
          />
        )}

        {nodeIsLoading && (
          <>
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </>
        )}

        {showOption === "States" && (
          <>
            <div className="traversal__map__block">
              <StateMap
                optionSelection={linkLabel}
                nodeState={optionData}
                retraverseState={reTraverseData}
                state={state}
                onStateChange={handleStateChange}
                manualSelectedStates={manualSelectedStates}
                setManualSelectedStates={setManualSelectedStates}
                manualUnselectedStates={manualUnselectedStates}
                setManualUnselectedStates={setManualUnselectedStates}
                groupByColumn={displayColumnName.split(" ")[1]}
                columnName={displayColumnName.split(" ")[0]}
                isSingle={selectedLink?.metaData?.isSingle}
              />
            </div>
          </>
        )}
        {showOption !== "States" && (
          <>
            {optionSelection !== "" && conditionNodeDetails.length == 2 && (
              <div className="traversal__product__block">
                {displayColumnName.split(" ")[1] !== "" &&
                displayColumnName.split(" ")[1] !== undefined ? (
                  <>
                    <MultiOptionWithGroupBy
                      groupByColumn={displayColumnName.split(" ")[1]}
                      columnName={displayColumnName.split(" ")[0]}
                      conditionNodeDetails={optionData}
                      selectedOptionFromConditionalNode={optionSelection}
                      optionNodeType={selectedLink?.type}
                      reTraverseData={reTraverseData}
                      linkLabel={linkLabel}
                      selectedValue={selectedValue}
                      isSingle={selectedLink?.metaData?.isSingle}
                      onFieldChange={handleFieldChange}
                      displayColumnForObject={displayColumnForObject}
                    />
                  </>
                ) : (
                  <>
                    <MultiOption
                      groupByColumn={displayColumnName.split(" ")[1]}
                      columnName={displayColumnName.split(" ")[0]}
                      conditionNodeDetails={optionData}
                      selectedOptionFromConditionalNode={optionSelection}
                      reTraverseData={reTraverseData}
                      optionNodeType={selectedLink?.type}
                      linkLabel={linkLabel}
                      selectedValue={selectedValue}
                      isSingle={selectedLink?.metaData?.isSingle}
                      onFieldChange={handleFieldChange}
                    />
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div className="card-footer">
        <TraversalFooter
          clickAble={optionSelection === ""}
          nextNode={() => handleConfirm()}
        />
      </div>
    </>
  );
}
