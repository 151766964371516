import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./TraversalRadioButton.scss";

export function TraversalRadioButton({ onSelect, dataForOption }) {
  return (
    <div className="TraversalRadioButton__main">
      <div className="form-group">
        <div className="radio-inline">
          {dataForOption.map((item, index) => {
            return (
              <div key={item._id + "_" + index} className="radio__input__box">
                <input
                  id={item._id}
                  className="radio-button"
                  type="radio"
                  value={item._id}
                  name="selection"
                  onClick={(e) => onSelect(e.target.value)}
                />
                <div className="radio-tile">
                  <label htmlFor={item._id} className="radio-tile-label">
                    <div className="radio__circle"></div>{" "}
                    {item.label.toUpperCase()}
                  </label>
                </div>
              </div>
            );
          })}

          {/* <div className="radio__input__box">
            <input
              id="no"
              value={noLinkId}
              className="radio-button"
              type="radio"
              name="selection"
              onClick={(e)=> onSelect(e.target.value)}
            />
            <div className="radio-tile">
              <label htmlFor="no" className="radio-tile-label">
                <div className="radio__circle"></div> No
              </label>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
