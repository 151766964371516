import React from "react";
import "./progressBar.scss";

export const ProgressBar = ({processPercentage}) => {
  
  return (
    <div style={{width:'100%'}} className="ProgressBar__main">
      <div style={{width:processPercentage > 0 ? processPercentage+'%' : 0 +'%'}} className="progressBar__filed">
      </div>
    </div>
  );
};