import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const startTraversCall = (question) => {
  return Services({
    url: "/user/traversal/",
    method: "POST",
    data: question,
  })
    .then((response) => {
      // response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};

export const getNextNodeDetails = (data) => {
  let appentUrl =
    "?questionId=" + data.questionId + "&transactionId=" + data.transactionId;
  if (data?.linkId !== undefined && data?.linkId !== "") {
    appentUrl = appentUrl + "&linkId=" + data?.linkId;
  }
  return Services({
    url: "/user/traversal" + appentUrl,
    method: "get",
  })
    .then((response) => {
      // response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};

export const getViewReportDetails = (transactionId) => {
  return Services({
    url: "user/traversal/summary?transactionId=" + transactionId,
    method: "get",
  })
    .then((response) => {
      // response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};
export const getDownloadReportDetails = (transactionId) => {
  return Services({
    url: "user/traversal/report?transactionId=" + transactionId,
    method: "get",
  })
    .then((response) => {
      // response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};
export const getSummaryData = (transactionId) => {
  return Services({
    url: "user/history/variable/" + transactionId,
    method: "get",
  })
    .then((response) => {
      // response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};
export const reTraversalApi = (transactionId) => {
  return Services({
    url: "user/traversal/" + transactionId,
    method: "put",
  })
    .then((response) => {
      // response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};

export const saveTraversalNodeApiCall = (nodeData) => {
  return Services({
    url: "/user/traversal/add",
    method: "POST",
    data: nodeData,
  })
    .then((response) => {
      // response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};

export const completeTraversalNodeApiCall = (nodeData) => {
  return Services({
    url: "/user/traversal/complete",
    method: "POST",
    data: nodeData,
  })
    .then((response) => {
      // response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};

export const getNodeDetailsApiCall = ({ decisionNodeId, transactionId }) => {
  return Services({
    url: "/user/traversal/getDecisionNode",
    method: "POST",
    data: { decisionNodeId: decisionNodeId, transactionId: transactionId },
  })
    .then((response) => {
      // response.data?.message && setToast(response.data?.message);
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message);
      return Promise.reject(error);
    });
};
