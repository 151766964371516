import React, { useState, useEffect } from "react";
import { uniq } from "lodash";
import { RingLoader } from "react-spinners";
import _unionBy from "lodash/unionBy";
import Checkbox from "@material-ui/core/Checkbox";
import { IconButton, Tooltip } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import UsaMapChart from "../../organization/UsaMapChart";
import { Services } from "../../../../_service/Services";
import "../Traversal.scss";

const COUNTRY_REGION_MAP = {
  all: "all",
  northeast: [
    "Connecticut",
    "Maine",
    "Massachusetts",
    "New Hampshire",
    "Rhode Island",
    "Vermont",
    "New Jersey",
    "New York",
    "Pennsylvania",
  ],
  southeast: [
    "Delaware",
    "Florida",
    "Georgia",
    "Maryland",
    "North Carolina",
    "South Carolina",
    "Virginia",
    "West Virginia",
    "Alabama",
    "Kentucky",
    "Mississippi",
    "Tennessee",
    "Arkansas",
    "Louisiana",
    "Oklahoma",
    "Texas",
  ],
  midwest: [
    "Illinois",
    "Indiana",
    "Michigan",
    "Ohio",
    "Wisconsin",
    "Iowa",
    "Kansas",
    "Minnesota",
    "Missouri",
    "Nebraska",
    "North Dakota",
    "South Dakota",
  ],
  west: [
    "Alaska",
    "Arizona",
    "California",
    "Colorado",
    "District of Columbia",
    "Hawaii",
    "Idaho",
    "Montana",
    "Nevada",
    "New Mexico",
    "Oregon",
    "Puerto Rico",
    "Utah",
    "Wyoming",
    "Washington",
  ],
};

export const StateMap = ({
  state,
  retraverseState,
  nodeState,
  onStateChange,
  manualSelectedStates,
  setManualSelectedStates,
  manualUnselectedStates,
  setManualUnselectedStates,
  optionSelection,
  groupByColumn,
  columnName,
  isSingle,
}) => {
  const [allState, setAllState] = useState([]);
  const [isDisable, setIsDisable] = useState(isSingle);
  const [isLoading, setIsLoading] = useState(false);
  const [checkStatus, setCheckStatus] = useState({
    all: false,
  });

  const [listGroupBy, setListGroupBy] = useState([]);

  useEffect(() => {
    let nodeStateList = nodeState.map((item) => {
      return item.list != undefined ? item.list : item;
    });
    let nodeStateListGroup = [].concat(...nodeStateList);
    setAllState(nodeStateListGroup);

    if (groupByColumn != undefined) {
      const stateGroups = uniq(
        nodeStateListGroup.map((prd) => prd?.[groupByColumn])
      );
      let newCheckStatus = { all: false };
      stateGroups.map((item) => {
        newCheckStatus[item.toLowerCase()] = false;
      });
      setListGroupBy(stateGroups);
      setCheckStatus(newCheckStatus);
    } else {
      setListGroupBy([]);
      setCheckStatus([]);
    }
  }, [nodeState]);

  useEffect(() => {
    if (optionSelection === "yes") {
      let newCheckStatus = { all: true };
      listGroupBy.map((item) => {
        newCheckStatus[item.toLowerCase()] = true;
      });

      setCheckStatus(newCheckStatus);
      onStateChange(allState, false);
      if (!isDisable) {
        setIsDisable(true);
      }
    } else {
      let newCheckStatus = { all: false };
      listGroupBy.map((item) => {
        newCheckStatus[item.toLowerCase()] = false;
      });

      setCheckStatus(newCheckStatus);
      setIsDisable(isSingle);
    }
  }, [optionSelection, allState]);
  useEffect(() => {
    if (checkStatus.all) {
      onStateChange(allState, false);
    } else if (Object.values(checkStatus).find((item) => item == true)) {
      let selectedStates = Object.keys(checkStatus).reduce(
        (o_selected_state, key) => {
          if (key !== "all") {
            if (checkStatus[key]) {
              const a_state = allState.filter(
                (o_state) =>
                  o_state?.[groupByColumn].toLowerCase() == key &&
                  !manualUnselectedStates.find(
                    (u_state) => u_state?.[columnName] === o_state?.[columnName]
                  )
              );
              o_selected_state = [
                ...o_selected_state,
                ...a_state?.map?.((state) => ({ ...state, isSelected: true })),
              ];
            }
          }
          return o_selected_state;
        },
        []
      );

      onStateChange([...selectedStates, ...manualSelectedStates], false);
    } else {
      const state = allState?.filter(
        (o_state) =>
          allState?.isSelected ||
          manualSelectedStates.find(
            (u_state) => u_state?.[columnName] === o_state?.[columnName]
          )
      );

      onStateChange(state, false);

      if (retraverseState.length > 0) {
        let uniqueState = retraverseState.filter(
          (retraversItem) =>
            manualUnselectedStates.findIndex(
              (unSelectedState) => unSelectedState._id === retraversItem._id
            ) == -1
        );

        onStateChange(uniqueState, false);
      }
    }
  }, [checkStatus]);

  const handleStateChange = (stateName) => {
    if (!stateName) {
      return;
    }
    if (!isSingle) {
      if (
        !state?.find(
          (o_state) =>
            o_state?.[columnName]?.toLowerCase?.() ===
            stateName?.toLowerCase?.()
        )
      ) {
        const newState = allState?.find(
          (o_state) =>
            o_state?.[columnName]?.toLowerCase?.() ===
            stateName?.toLowerCase?.()
        );
        if (newState) {
          const updatedState = [...state, newState];
          setManualSelectedStates([
            ...manualSelectedStates,
            { ...newState, isSelected: true },
          ]);
          manualUnselectedStates = manualUnselectedStates?.filter?.(
            (o_state) =>
              o_state?.[columnName]?.toLowerCase?.() !==
              stateName?.toLowerCase?.()
          );
          setManualUnselectedStates([...manualUnselectedStates]);
          onStateChange(updatedState);
        }
      } else {
        manualSelectedStates = manualSelectedStates?.filter(
          (o_state) =>
            o_state?.[columnName]?.toLowerCase?.() !==
            stateName?.toLowerCase?.()
        );
        setManualSelectedStates([...manualSelectedStates]);
        const newState = allState?.find(
          (o_state) =>
            o_state?.[columnName]?.toLowerCase?.() ===
            stateName?.toLowerCase?.()
        );
        if (newState) {
          setManualUnselectedStates((oldState) => [
            ...oldState,
            { ...newState, isSelected: false },
          ]);
        }
        let statechange = [...state];
        statechange = statechange?.filter(
          (o_state) =>
            o_state?.[columnName]?.toLowerCase?.() !==
            stateName?.toLowerCase?.()
        );
        onStateChange(statechange);
      }
    } else {
      if (stateName != undefined) {
        let newState;

        let available = state?.filter(
          (selected) =>
            selected?.[columnName]?.toLowerCase?.() ===
            stateName?.toLowerCase?.()
        );

        if (available.length > 0) {
          onStateChange([]);
        } else {
          newState = allState?.find(
            (o_state) =>
              o_state?.[columnName]?.toLowerCase?.() ===
              stateName?.toLowerCase?.()
          );
          onStateChange([newState]);
        }
      }
    }
  };

  const handleChange = (id, e) => {
    setCheckStatus((oldValue) => {
      if (id === "all") {
        let newCheckStatus = { all: e.target.checked };
        listGroupBy.map((item) => {
          newCheckStatus[item.toLowerCase()] = e.target.checked;
        });

        return newCheckStatus;
      }
      if (e.target.checked) {
        const hasAll = Object.keys(checkStatus).reduce((b_has_all, key) => {
          if (key === "all" || key?.toLowerCase?.() === id?.toLowerCase?.()) {
            return b_has_all;
          }
          if (!checkStatus[key]) {
            b_has_all = false;
          }
          return b_has_all;
        }, true);
        if (hasAll) {
          return { ...oldValue, [id]: e.target.checked, all: true };
        }
      } else if (id !== "all" && checkStatus.all) {
        return { ...oldValue, [id]: e.target.checked, all: false };
      }
      return { ...oldValue, [id]: e.target.checked };
    });
  };

  return isLoading ? (
    <div className="loader_wrapper">
      <RingLoader color={"darkcyan"} loading={true} size={40} />
    </div>
  ) : (
    <>
      <div className="custom-map-checkbox">
        {listGroupBy.length > 0 && (
          <>
            <Checkbox
              id="all"
              checked={checkStatus?.all}
              onChange={handleChange.bind(this, "all")}
              disabled={isDisable}
            />
            <label htmlFor="all" className="all-country-label">
              All
            </label>
            {listGroupBy.map((item, index) => {
              return (
                <React.Fragment key={item + "_" + index}>
                  <Checkbox
                    id={item + "_" + index}
                    checked={checkStatus[item.toLowerCase()]}
                    onChange={handleChange.bind(this, item.toLowerCase())}
                    disabled={isDisable}
                  />
                  <label htmlFor={item + "_" + index}>{item}</label>
                </React.Fragment>
              );
            })}
          </>
        )}

        {optionSelection !== "yes" && (
          <div style={{ marginLeft: 5, marginTop: -5 }}>
            <Tooltip
              title="Reset state"
              PopperProps={{
                className: "refresh-view-tooltip",
              }}
            >
              <IconButton
                className="btn btn-icon btn-light btn-hover-primary btn-sm"
                onClick={() => {
                  let newCheckStatus = { all: false };
                  listGroupBy.map((item) => {
                    newCheckStatus[item.toLowerCase()] = false;
                  });

                  setCheckStatus(newCheckStatus);
                  setManualSelectedStates([]);
                  setManualUnselectedStates([]);
                  onStateChange([]);
                }}
                aria-label="detail"
                style={{
                  marginLeft: 3,
                  marginTop: 7,
                  marginRight: 10,
                  marginBottom: 26,
                  height: 34,
                  width: 34,
                  borderRadius: 5,
                  color: "#3699FF",
                }}
              >
                <Refresh className="reset" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <div
        style={{
          width: "50%",
          margin: "0 auto",
        }}
      >
        <div
          className="card-body py-0"
          style={{
            pointerEvents: optionSelection === "yes" ? "none" : "",
          }}
        >
          <UsaMapChart
            state={state || []}
            columnName={columnName}
            onStateChange={handleStateChange}
          />
        </div>
      </div>
    </>
    // </div>
  );
};
