export const getUsersList = (store) => store.user.data;

export const getUserIsLoading = (store) => store.user.isLoading;

export const getUsersCount = (store) => store.topics.data.length;

export const getUser = (id) => (store) =>
  store.users.data.find((user) => user._id === id);

export const getUserTraverseQuestion = (store) => store.user.questions || [];
