import { uniqueId } from "lodash";
import { Services } from "../Services";
import { subtopic } from "../subtopics/subtopics.service";
import { setToast } from "../../app/modules/toast";

//backend fetch
export const addTopic = (topic) =>
  Services({
    url: "/admin/topic",
    method: "POST",
    data: { name: topic },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const updateTopic = (id, topic) =>
  Services({
    url: `/admin/topic/${id}`,
    method: "PUT",
    data: { name: topic },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const getTopic = () =>
  Services({
    url: "/admin/topic",
    method: "GET",
  });

export const getTopicDetails = (id) =>
  Services({
    url: `/admin/topic/details/${id}`,
    method: "GET",
  });

export const deleteTopic = (id) =>
  Services({
    url: `/admin/topic/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

//mock
let topics = [
  {
    _id: "62a80effe2b34a44a0b9f192",
    name: "Supply Chain Strategy Assessment",
    updatedAt: "2022-06-14T04:38:39.981Z",
    createdAt: "2022-06-14T04:30:55.656Z",
    subTopicsCount: 2,
  },
  {
    _id: "62a80f64e2b34a44a0b9f196",
    name: "Network Design",
    updatedAt: "2022-06-14T04:32:36.060Z",
    createdAt: "2022-06-14T04:32:36.060Z",
    subTopicsCount: 3,
  },
  {
    _id: "62a837348fefd2471c1bdf70",
    name: "IT Tech Stack Review",
    updatedAt: "2022-06-14T07:22:28.777Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    subTopicsCount: 1,
  },
];
export const topic = { topics };

export const _addTopic = (topicName) => {
  const date = new Date().toGMTString();
  topic.topics.push({
    _id: uniqueId(),
    name: topicName,
    updatedAt: date,
    createdAt: date,
    subtopicIds: [],
    subTopicsCount: 0,
  });

  const promise = new Promise((res) => {
    res({
      data: { name: topic },
    });
  });

  return promise;
};

export const _updateTopic = (id, topicName) => {
  const date = new Date().toGMTString();
  topic.topics = topic.topics.map((topicObj) => {
    if (topicObj._id === id) {
      topicObj.name = topicName;
      topicObj.updatedAt = date;
    }
    return topicObj;
  });

  const promise = new Promise((res) => {
    res({
      data: { name: topicName },
    });
  });

  return promise;
};

export const _getTopic = () => {
  const promise = new Promise((res) => {
    res({
      data: {
        code: 200,
        message: "Topic Get successfully!",
        data: topic.topics,
        error: [],
      },
    });
  });

  return promise;
};

export const _getTopicDetails = (id) => {
  const subtopics = subtopic.subtopics.filter(
    (subtopic) => subtopic.parentTopic === id
  );
  const topicDetail = topic.topics.find((topicObj) => topicObj._id === id);

  const promise = new Promise((res) => {
    res({
      data: {
        code: 200,
        message: "Topic Get successfully!",
        data: [
          {
            ...topicDetail,
            subTopics: subtopics,
          },
        ],
        error: [],
      },
    });
  });

  return promise;
};

export const _deleteTopic = (id) => {
  topic.topics = topic.topics.filter((topicObj) => topicObj._id !== id);

  const promise = new Promise((res) => {
    res({
      data: {
        code: 200,
        message: "Topic deleted successfully!",
        data: [],
        error: [],
      },
    });
  });

  return promise;
};
