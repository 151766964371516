import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import RingLoader from "react-spinners/RingLoader";
import { actions as TRAVERS_HISTORY_ACTIONS } from "../../../../app/modules/TraversHistory/_redux/TraversHistoryRedux";
import { Services } from "../../../../_service/Services";
import { setToast } from "../../../../app/modules/toast";
import parse from "html-react-parser";

export function HistoryReportDialogue({
  toggleModal,
  transactionId,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    Services({
      url: `/user/traversal/summary?transactionId=${transactionId}`,
      method: "GET",
    })
      .then((res) => {
        setData(res?.data?.data?.displayHtml);
      })
      .catch((error) => {
        let message = "Report missing!";
        if (error?.response?.data?.message) {
          message = error.response.data.message;
        }
        setData(message);
        setToast(message, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        toggleModal(false);
      }}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">History Report Detail</h3>{" "}
        <IconButton
          position="top right"
          aria-label="close"
          onClick={() => {
            toggleModal(false);
          }}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "20vh",
            }}
            className=""
          >
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </div>
        )}
        {data && <div className="report-div-html">{parse(data)}</div>}
      </Modal.Body>
    </Modal>
  );
}
