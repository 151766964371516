import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { RingLoader } from "react-spinners";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import "./SummaryAnalysis.scss";
import { ViewReport } from "../TraversalComponent/ViewReport";
import { traversalSectionHeight } from "../../../_utils/constants";
export function SummaryAnalysis({ nodeData, transactionId, questionId }) {
  const dispatch = useDispatch();
  const downloadEnable = useSelector(
    (store) => store.webTravers.downloadReprtReady
  );
  const reportIsLoading = useSelector(
    (store) => store.webTravers.reportIsLoading
  );
  const downloadData = useSelector((store) => store.webTravers.downloadReport);
  const [viewReportModel, setViewReportModel] = useState(false);
  

  useEffect(() => {
    if (downloadEnable) {
      const newWindow = window.open(
        downloadData,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
    return () => {
      dispatch(WEB_TRAVERS.setDownloadReportInitialData());
    };
  }, [downloadEnable]);
  const handleDownloadReport = () => {
    dispatch(WEB_TRAVERS.getDownloadReportApi(transactionId));
  };
  const handleConfirm = () => {
    let data = {
      fromLink: nodeData.fromLink,
      toNode: nodeData._id,
      transactionId: transactionId,
      nextNodeLinkId: nodeData.links[0]._id,
      questionId: questionId,
      metaData: {
        variableName: nodeData.metaData.variableName,
      },
    };
    dispatch(WEB_TRAVERS.saveNodeDataApi(data));
  };
  const [showContinueButtonAbove, setShowContinueButtonAbove] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref != null) {
      setShowContinueButtonAbove(
        ref.current.clientHeight > traversalSectionHeight
      );
     
    }
  });
  return (
    <div className="SummaryAnalysis__main" ref={ref}>
      <div className="card-body">
        <div className="card-header">
          <h3 className="card-title flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Summary Analysis
            </span>
          </h3>
          {showContinueButtonAbove && (
            <TraversalFooter nextNode={() => handleConfirm()} />
          )}
        </div>

        <div className="suggest__actionbox">
          <h5 className="font-weight-bolder text-dark">Suggested Action</h5>
          <p className="text-dark">
            Please click below to download your generated report.
          </p>
          <div className="suggest__actionbox__btn pt-2">
            <button
              className="btn btn-primary font-size-lg mr-4"
              onClick={() => {
                setViewReportModel(true);
              }}
            >
              View Report
            </button>
            <button
              className="btn btn-success font-size-lg"
              onClick={() => handleDownloadReport()}
              disabled={reportIsLoading}
            >
              Download Report
            </button>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <TraversalFooter nextNode={() => handleConfirm()} />
      </div>
      <Modal
        size="lg"
        className=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={viewReportModel}
        onHide={() => {
          setViewReportModel(false);
        }}
      >
        <Modal.Body>
          <h3 className="mt-2 pb-6">Report Detail</h3>{" "}
          <IconButton
            position="top right"
            aria-label="close"
            onClick={() => {
              setViewReportModel(false);
            }}
            style={{
              position: "absolute",
              zIndex: 1111,
              right: 22,
              top: 17,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <ViewReport transactionId={transactionId} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
