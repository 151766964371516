import React from "react";
import SubCategorySlider from "./SubCategorySlider";
import "./ToolboxSubCategory.scss";

export function ToolboxSubCategory() {
  return (
    <div className="ToolboxSubCategory__main SubCategory__fullbg">
      <div className="Toolbox__top__contant">
        <div className="leftcol">
          <h2>
            <span className="backarrow"></span>Operational Tools
          </h2>
          <p>Assessment Tools to Evaluate the Changes in Operational Tactics</p>
        </div>
        <div className="rightcol__logo">
          <div className="pow-logo">
            <img alt="powemate" src="/media/logos/pow-emate.png" />
          </div>
        </div>
      </div>
      <div className="SubCategory__middle__block">
        <div className="SubCategory__contant__text">
          <h3>What Happens if? .....</h3>
          <p>
            Evaluating changes in operating strategy help align Doing with
            Customer Satisfaction
          </p>
        </div>
        <div className="illustration__img">
          <img alt="illustration" src="/media/bg/sub-cat-illustration.png" />
        </div>
      </div>
      <div className="Toolbox__bottom__Slider">
        <SubCategorySlider/>
      </div>
      <div className="bottom-fixed">
        <div className="bottom__tagline">Solution Selection Bar.... Choose the Solution of Interest</div>
        <div className="bottom-imgline">
          <img alt="bottom-img" src="/media/misc/subcategory-bottomfix.png" />
        </div>
      </div>
    </div>
  );
}
