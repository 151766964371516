import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://emate.ics-global.in:64460/";
let headers = {
  Accept: "application/json",
};

const instance = axios.create({
  baseURL: API_URL,
  // timeout: 100000,
  headers: headers,
  
});

export const Services = instance;
