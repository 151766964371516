import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@material-ui/core/Chip";
import { IconButton } from "@material-ui/core";
import { useParams, Link } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import RingLoader from "react-spinners/RingLoader";
import { ProgressBar } from "../../customComponents/progressBar/progressBar";

import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import "./TraversalHeader.scss";

export function TraversalHeader({ questionDetails, transactionId }) {
  const dispatch = useDispatch();
  const [completed, setCompleted] = useState(0);
  const [openViewSummaryModal, SetopenViewSummaryModal] = useState(false);
  const [openAssumptionModal, SetopenAssumptionModal] = useState(false);
  const [openDescriptionModal, SetopenDescriptionModal] = useState(false);

  const dataIsLoading = useSelector((store) => store.webTravers.dataIsLoading);
  const summaryData = useSelector((store) => store.webTravers.summaryData.item);
  const processPercentage = useSelector(
    (store) => store.webTravers.nodeData?.transactionPercentage
  );
  const displayNames = useSelector(
    (store) => store.webTravers.summaryData.displayColumn
  );

  const handleSummaryData = () => {
    dispatch(WEB_TRAVERS.getSummaryDataApi(transactionId));
    SetopenViewSummaryModal(true);
  };
  return (
    <div className="TraversalHeader__main">
      <div className={`card card-custom`}>
        <div className="card-header py-2 align-items-center">
          <div className="text-div">
            <Link
              to={{
                pathname: "/toolbox/solution/" + questionDetails?.topicId,
              }}
            >
              <IconButton
                className="btn btn-icon btn-light btn-hover-primary btn-sm"
                aria-label="detail"
                style={{
                  marginRight: 10,
                  height: 34,
                  width: 34,
                  borderRadius: 5,
                  color: "#3699FF",
                }}
              >
                <ArrowBackIos className="back" />
              </IconButton>
            </Link>
            <h3 className="card-title flex-column m-0">
              <span className="card-label font-weight-bolder text-dark">
                {questionDetails?.text}
              </span>
            </h3>
          </div>

          <div className="card-toolbar">
            <button
              className="btn btn-primary font-size-md"
              style={{ pointerEvents: transactionId === "" ? "none" : "" }}
              onClick={() => {
                handleSummaryData();
              }}
            >
              View Scenario Summary
            </button>
            <button
              className="btn btn-primary font-size-md ml-4"
              onClick={() => {
                SetopenAssumptionModal(true);
              }}
            >
              Assumption
            </button>
            <button
              className="btn btn-primary font-size-md ml-4"
              onClick={() => {
                SetopenDescriptionModal(true);
              }}
            >
              Description
            </button>
          </div>
        </div>
        <div className="ProgressBar__block">
          <ProgressBar
            processPercentage={processPercentage}
            completed={completed}
          />
        </div>
        <div className="ViewSummaryDialog">
          <Modal
            size="md"
            className="traversal__modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={openViewSummaryModal}
            onHide={() => {
              SetopenViewSummaryModal(false);
            }}
          >
            <Modal.Body>
              <div className="mb-4 p-0 d-flex align-items-center justify-content-between">
                <h3 className="mb-0">View Summary</h3>
                <button
                  className="modal__crossicon"
                  onClick={() => {
                    SetopenViewSummaryModal(false);
                  }}
                ></button>
              </div>
              <div className="mb-0 p-0">
                {dataIsLoading && (
                  <div className="loader_wrapper">
                    <RingLoader color={"darkcyan"} loading={true} size={40} />
                  </div>
                )}

                {openViewSummaryModal && summaryData.length > 0 && (
                  <>
                    {summaryData.map((item, index) => {
                      let key = Object.keys(item)[0];
                      if (
                        typeof item[key] === "string" ||
                        typeof item[key] === "number"
                      ) {
                        // const result = item.value.replace(/([A-Z])/g, " $1");
                        // const header = item.key.charAt(0).toUpperCase() + result.slice(1);
                        return (
                          <div
                            key={index}
                            className="summary__list d-flex align-items-center justify-content-between"
                          >
                            <p>{key}</p>
                            <span className="number">{item[key]}</span>
                          </div>
                        );
                      }
                      if (typeof item[key] === "object") {
                        let dataValue = item[key];
                        let displayNamevalue = displayNames
                          .find((name) => Object.keys(name)[0] == key)
                          ?.[key].split(" ");

                        let namesForDisplay =
                          displayNamevalue.length > 0 &&
                          displayNamevalue[0] != "Category"
                            ? displayNamevalue[0]
                            : displayNamevalue[1];
                        const header = key;
                        if (key !== "reportSummary") {
                          return (
                            <div
                              key={index + "_" + header}
                              className="summary__list d-flex align-items-center justify-content-between"
                            >
                              <p>{header}</p>
                              <span className="number">
                                {dataValue.map((item) => {
                                  if (namesForDisplay != undefined) {
                                    return (
                                      <Chip
                                        key={item?.[namesForDisplay]}
                                        style={{ margin: "4px" }}
                                        label={item?.[namesForDisplay]}
                                        variant="outlined"
                                      />
                                    );
                                  } else {
                                    return (
                                      <Chip
                                        key={item}
                                        style={{ margin: "4px" }}
                                        label={item}
                                        variant="outlined"
                                      />
                                    );
                                  }
                                })}
                              </span>
                            </div>
                          );
                        } else {
                          return "";
                        }
                      }
                    })}
                  </>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div className="AssumptionDialog">
          <Modal
            size="md"
            className="traversal__modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={openAssumptionModal}
            onHide={() => {
              SetopenAssumptionModal(false);
            }}
          >
            <Modal.Body>
              <div className="mb-4 p-0 d-flex align-items-center justify-content-between">
                <h3 className="font-weight-bolder text-dark mb-0">
                  Assumption
                </h3>
                <button
                  className="modal__crossicon"
                  onClick={() => {
                    SetopenAssumptionModal(false);
                  }}
                ></button>
              </div>
              <div
                className="mb-0 p-0"
                dangerouslySetInnerHTML={{
                  __html: questionDetails?.assumptions,
                }}
              ></div>
            </Modal.Body>
          </Modal>
        </div>
        <div className="DescriptionDialog">
          <Modal
            size="md"
            className="traversal__modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={openDescriptionModal}
            onHide={() => {
              SetopenDescriptionModal(false);
            }}
          >
            <Modal.Body>
              <div className="mb-4 p-0 d-flex align-items-center justify-content-between">
                <h3 className="font-weight-bolder text-dark mb-0">
                  Description
                </h3>
                <button
                  className="modal__crossicon"
                  onClick={() => {
                    SetopenDescriptionModal(false);
                  }}
                ></button>
              </div>
              <div
                className="mb-0 p-0"
                dangerouslySetInnerHTML={{
                  __html: questionDetails?.description,
                }}
              ></div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
