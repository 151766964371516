// import { getUrl } from "../url";
import { userQuestionTraverseResponse } from "./_mockUserQuestions";
import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const addUser = (user) =>
  Services({
    url: "/admin/user",
    method: "POST",
    data: {
      ...user,
      userRole:
        typeof user?.userRole === "string" ? [user?.userRole] : user?.userRole,
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const updateUser = (user) =>
  Services({
    url: `/admin/user/${user.id}`,
    method: "PUT",
    data: {
      firstName: user.firstName,
      lastName: user.lastName,
      countryCode: user.countryCode,
      phone: user.phone,
      designation: user.designation,
      userRole:
        typeof user?.userRole === "string" ? [user?.userRole] : user?.userRole,
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const changePassword = (passwordDetails) =>
  Services({
    url: `/admin/profile/change-password`,
    method: "PUT",
    data: passwordDetails,
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const deleteUser = (id) =>
  Services({
    url: `/admin/user/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const getUsers = (pageNo = 1) =>
  Services({
    url: `/admin/user/?page=${pageNo}`,
    method: "GET",
  });

export const getUserTraverse = (id) =>
  Services({
    url: `/user/history/?userId=${id}`,
    method: "GET",
  });

//backend fetch
// export const addUser = (user) =>
//   Services({
//     url: getUrl("user"),
//     method: "POST",
//     body: user,
//   });

// export const updateUser = (user) => {
//   const { id, ...rest } = user;
//   return Services({
//     url: getUrl("user") + "/" + id,
//     method: "PUT",
//     body: rest,
//   });
// };

// export const getUsers = () =>
//   Services({
//     url: getUrl("user"),
//     method: "GET",
//   });

// export const deleteUser = (id) =>
//   Services({
//     url: getUrl("user") + "/" + id,
//     method: "DELETE",
//   });

//mock
let users = [
  {
    designation: "SD",
    email: "testuser01@email.com",
    firstName: "Test",
    _id: "2",
    lastName: "User",
    password: "",
    phone: "0123456790",
    updatedAt: "2022-06-14T04:38:39.981Z",
    createdAt: "2022-06-14T04:30:55.656Z",
  },
];

export const _addUser = (user) => {
  const date = new Date().toGMTString();
  users.push({
    _id: Math.floor(Math.random() * 10000 + 1),
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    designation: user.designation,
    password: user.password,
    updatedAt: date,
    createdAt: date,
  });

  const promise = new Promise((res) => {
    res({
      data: user,
    });
  });

  return promise;
};

export const _updateUser = (user) => {
  // console.log();
  const date = new Date().toGMTString();
  users = users.map((userObj) =>
    userObj._id === user.id ? { ...user, updatedAt: date } : userObj
  );

  const promise = new Promise((res) => {
    res({
      data: user,
    });
  });

  return promise;
};

export const _getUsers = () => {
  const promise = new Promise((res) => {
    res({
      data: {
        code: 200,
        message: "Users Fetched successfully!",
        data: users,
        error: [],
      },
    });
  });

  return promise;
};

export const _deleteUser = (id) => {
  users = users.filter((userObj) => userObj._id !== id);

  const promise = new Promise((res) => {
    res({
      data: {
        code: 200,
        message: "User deleted successfully!",
        data: [],
        error: [],
      },
    });
  });

  return promise;
};

export const _getUserTraverse = (id) => {
  const promise = new Promise((res) => {
    setTimeout(() => {
      res(userQuestionTraverseResponse);
    }, 1000);
  });

  return promise;
};

export const user = { users };
