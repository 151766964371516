import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";


export const uploadFile = (fileData) => {
    
    return Services({
      url: "/admin/excel-data/excel-sheets",
      method: "POST",
      data: fileData,
    })
      .then((response) => {
        response.data?.message && setToast(response.data?.message);
        return response;
      })
      .catch((error) => {
        error?.response?.data?.message &&
          setToast(error?.response?.data?.message);
        return Promise.reject(error);
      });
  };