import { uniqueId } from "lodash";
import { setToast } from "../../app/modules/toast";
import { Services } from "../Services";
import { subtopic } from "../subtopics/subtopics.service";

export const addQuestion = (questionText, parentSubtopic) => {
  return Services({
    url: "/admin/question",
    method: "POST",
    data: {
      "text" :  questionText.text,
      "assumptions" :  questionText.assumptions,
      "screen_tagline" :  questionText.screen_tagline,
      "description" :  questionText.description,
      "summery_report" :  questionText.summery_report,
      "topicId" : questionText.topicId,
      "icon_logo":questionText.icon_logo,
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const updateQuestion = (id, questionText) => {
  return Services({
    url: `/admin/question/${id}`,
    method: "PUT",
    data: {
      "text" :  questionText.text,
      "assumptions" :  questionText.assumptions,
      "screen_tagline" :  questionText.screen_tagline,
      "description" :  questionText.description,
      "summery_report" :  questionText.summery_report,
      "icon_logo": questionText.icon_logo,
  },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const deleteQuestion = (id) => {
  return Services({
    url: `/admin/question/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getSubtopicDetails = (id) => {
  return Services({
    url: `/admin/subTopic/${id}`,
    method: "GET",
  });
};

let questions = [
  {
    _id: "62a9cf2af912b33c147293d6",
    text: "Do you have  clear process on how to user/implement the software?",
    updatedAt: "2022-06-15T12:23:06.339Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    parentSubtopic: "62a8243346e2bc3c641f8f6d",
    modifiedBy: {
      firstName: "Intech",
      lastName: "solution",
    },
  },
  {
    _id: "62a9cf30f912b33c147293d8",
    text: "question 3",
    updatedAt: "2022-06-15T12:23:12.710Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    parentSubtopic: "62a8243346e2bc3c641f8f6d",
    modifiedBy: {
      firstName: "Intech",
      lastName: "solution",
    },
  },
  {
    _id: "62a9cf35f912b33c147293da",
    text: "question 4",
    updatedAt: "2022-06-15T12:23:17.944Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    parentSubtopic: "62a8245146e2bc3c641f8f71",
    modifiedBy: {
      firstName: "Intech",
      lastName: "solution",
    },
  },
];

export const question = { questions };

export const _addQuestions = (questionText, parentSubtopic) => {
  const date = new Date().toGMTString();
  question.questions.push({
    _id: uniqueId(),
    text: questionText,
    updatedAt: date,
    createdAt: date,
    parentSubtopic: parentSubtopic,
    modifiedBy: {
      firstName: "Intech",
      lastName: "solution",
    },
  });

  subtopic.subtopics = subtopic.subtopics.map((subtopic) => {
    if (subtopic._id === parentSubtopic) {
      subtopic.questionCount += 1;
    }
    return subtopic;
  });

  const promise = new Promise((res) => {
    res({
      data: { text: question, parentSubtopic },
    });
  });

  return promise;
};

export const _updateQuestion = (id, questionText) => {
  const date = new Date().toGMTString();
  question.questions = question.questions.map((questionObj) => {
    if (questionObj._id === id) {
      questionObj.text = questionText;
      questionObj.updatedAt = date;
    }
    return questionObj;
  });

  const promise = new Promise((res) => {
    res({
      data: { name: questionText },
    });
  });

  return promise;
};

export const _deleteQuestion = (id, parentSubtopic) => {
  question.questions = question.questions.filter(
    (questionObj) => questionObj._id !== id
  );

  subtopic.subtopics = subtopic.subtopics.map((subtopic) => {
    if (subtopic._id === parentSubtopic) {
      subtopic.questionCount -= 1;
    }
    return subtopic;
  });

  const promise = new Promise((res) => {
    res({
      data: {
        code: 200,
        message: "Question deleted successfully!",
        data: [],
        error: [],
      },
    });
  });

  return promise;
};
