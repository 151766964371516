import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _unionBy from "lodash/unionBy";
import { TraversalRadioButton } from "../TraversalRadioButton/TraversalRadioButton";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import "../Traversal.scss";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import { traversalSectionHeight } from "../../../_utils/constants";
export function MultiAns({ nodeData, transactionId, questionId }) {
  const dispatch = useDispatch();

  const [optionSelection, setOptionSelection] = useState("");


  const handleConfirm = () => {
    let data = {
      fromLink: nodeData.fromLink,
      toNode: nodeData._id,
      transactionId: transactionId,
      nextNodeLinkId: optionSelection,
      questionId: questionId,
      nextLinkSelection: optionSelection,
    };
    dispatch(WEB_TRAVERS.saveNodeDataApi(data));
  };

  const handleSelection = (value) => {
    setOptionSelection(value);
  };
  const [showContinueButtonAbove, setShowContinueButtonAbove] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref != null) {
      setShowContinueButtonAbove(
        ref.current.clientHeight > traversalSectionHeight
      );
    
    }
  });
  return (
    <>
      <div className="card-body" ref={ref}>
        <div className="card-header">
          <h3 className="card-title flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {nodeData.label}
            </span>
          </h3>
          {showContinueButtonAbove && (
            <TraversalFooter
              clickAble={optionSelection === ""}
              nextNode={() => handleConfirm()}
            />
          )}
        </div>

        <TraversalRadioButton
          dataForOption={nodeData.links}
          onSelect={(value) => handleSelection(value)}
        />
      </div>
      <div className="card-footer">
        <TraversalFooter
          clickAble={optionSelection === ""}
          nextNode={() => handleConfirm()}
         
        />
      </div>
    </>
  );
}
