import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationsList,
  getIsLoading,
} from "../../../../app/modules/Organizations/_redux/organizationsSelectors";
import { actions as Organizations } from "../../../../app/modules/Organizations/_redux/organizationsRedux";

import RingLoader from "react-spinners/RingLoader";
import "./common.css";
import UsaMapChart from "../../organization/UsaMapChart";
import "../../../_partials/modals/editorganization-dialog/organization-table.css";
const KeyLabelMap = {
  eCommerceRevenuePercentage: "E Commerce Revenue(%)",
  traditionalRetailRevenuePercentage: "Traditional Retail Revenue(%)",
  iconLogo: "Logo",
};

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function OrganizationTableWidget1({ className }) {
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizationsList);
  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    dispatch(Organizations.getOrganizations());
  }, []);

  
 

  return (
    <div className={`card card-custom ${className}`}>
      {/* begin::Header */}
      {isLoading && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      <div className="card-header border-0 py-8 custom-header align-items-center">
        <h3 className="card-title flex-column m-0">
          <span className="card-label font-weight-bolder text-dark">
            Organization
          </span>
        </h3>
      </div>
     
      {/* end::Header */}

      {/* begin::Body */}
      {organizations && (
        <div className="card-body py-0">
          
          <div className="table-responsive">
            <table
              style={{ border: "none", width: "100%" }}
              className="custom-table"
              id="kt_advance_table_widget_1"
              cellPadding={10}
              cellSpacing={10}
            >
              <tbody>
              {[
                
                "companyName",
                "overAllRevenue",
                "eCommerceRevenuePercentage",
                "traditionalRetailRevenuePercentage",
                "eCommerceUnit",
                "traditionalRetailUnit",
                "products",
              ].map((key) => {
                if (key === "_id") {
                  return null;
                }
                if (!organizations[key]) {
                  return null;
                }
                if (key === "iconLogo") {
                  return (
                    <tr key={key}>
                      <th style={{ width: "55%" }}>{KeyLabelMap[key]}</th>
                      <td>
                        <img src={organizations[key]} style={{ height: 50 }} />
                      </td>
                    </tr>
                  );
                }
                if (typeof organizations[key] !== "object") {
                  const result = key.replace(/([A-Z])/g, " $1");
                  const header =
                    result.charAt(0).toUpperCase() + result.slice(1);
                  let tableValue = organizations[key];
                  if (key === "overAllRevenue") {
                    tableValue = formatter.format(tableValue);
                  }
                  if (
                    [
                      "traditionalRetailRevenuePercentage",
                      "eCommerceRevenuePercentage",
                    ].indexOf(key) > -1
                  ) {
                    tableValue = `${tableValue}%`;
                  }
                  return (
                    <tr key={key}>
                      <th style={{ width: "55%" }}>
                        {KeyLabelMap[key] || header}
                      </th>
                      <td>{tableValue}</td>
                    </tr>
                  );
                } else {
                  const tableData =
                    key === "products"
                      ? organizations[key]
                          ?.filter?.((prd) => prd?.isSelected)
                          ?.map?.(({ Category, Name }) => ({
                            Category,
                            Name,
                          }))
                      : organizations[key];

                  if (!tableData.length) {
                    return null;
                  }

                  const [header] = tableData;
                  const value = (
                    <table
                      cellPadding={8}
                      cellSpacing={8}
                      style={{ border: "none", width: "100%" }}
                    >
                      <thead>
                        <tr>
                          {Object.keys(header)
                            .reverse()
                            .map((headerKey) => {
                              if (headerKey === "_id") {
                                return null;
                              }
                              const result = headerKey.replace(
                                /([A-Z])/g,
                                " $1"
                              );
                              const title =
                                result.charAt(0).toUpperCase() +
                                result.slice(1);
                              return <th key={headerKey}>{title}</th>;
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row,index) => (
                          <tr key={index+'_'+row}>
                            {Object.keys(row)
                              .reverse()
                              .map((rowKey) => {
                                if (rowKey === "_id") {
                                  return null;
                                }
                                return <td key={rowKey}>{row[rowKey]}</td>;
                              })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  );

                  const result = key.replace(/([A-Z])/g, " $1");
                  const title =
                    result.charAt(0).toUpperCase() + result.slice(1);
                  return (
                    <React.Fragment key={title}>
                      <tr>
                        <th colSpan="2">{title}</th>
                      </tr>
                      <tr>
                        <td colSpan="2">{value}</td>
                      </tr>
                    </React.Fragment>
                  );
                }
              })}
              <tr>
                <th colSpan="2">State</th>
              </tr>
              <tr>
                <td colSpan="2">
                  <div
                    style={{
                      pointerEvents: "none",
                      width: "100%",
                      maxWidth: "720px",
                      margin: "0 auto",
                    }}
                  >
                    <UsaMapChart
                      allState={organizations?.state}
                      state={organizations?.state?.filter?.(
                        (o_state) => o_state?.isSelected
                      )}
                    />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
      )}
      {/* end::Body */}
    </div>
  );
}
