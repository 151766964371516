import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const getSinglePageData = (pageName, isToolBox) => {
  return Services({
    url: "/admin/help/" + pageName + "?isToolBox=" + isToolBox,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
