import React from "react";
import "./TraversalFooter.scss";

export function TraversalFooter({ nextNode, clickAble}) {
  
  return (
    <div className="TraversalFooter__main">
      <div className="traversal__action__btn">
        <button
          type="button"
          disabled={clickAble}
          onClick={nextNode}
          className="btn btn-primary btn__next"
        >
          Continue
        </button>
      </div>
    </div>
  );
}
