import React from "react";

import "./PrivacyPolicy.css";
export const PrivacyPolicy = () => {
  return (
    <html>
      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Executive Tool App - Privacy</title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
        />
      </head>

      <body className="pp-body">
        <section id="privacy">
          <div class="privacy-block">
            <div class="title">Privacy Policy</div>
            <div class="privacy-text">
              {/* <h3>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Praesent vestibulum molestie lacus.
              </h3> */}
              <p>
                We believe your information is private, confidential, and not to
                be shared with any partner until <b>you</b> want to share it.
                eMATE Consulting takes the protection of such data seriously and
                does not share it with any outside or Third-Party services.
              </p>
              <h3>What is confidential information?</h3>
              <p>
                This includes any data provided in our environment. Whether it
                is a work email, phone number, or user job title we do not
                disclose this information to anyone. Your information is 100%
                protected when using our solutions. In our standard set-up
                process, we ask for revenue, selling states, and modes of
                transportation mix. This is stored in a secure cloud environment
                where only you can access it. This data never leaves the
                security of your environment and not harvested and used for any
                other purpose.
              </p>
              <h3>Coverage</h3>
              <p>
                This Privacy Policy sets forth by eMATE Consulting, LLC’s
                (“eMATE”, “we”, “us”, or “our”) privacy practices in connection
                with eMATE Consulting, LLC’s website located at
                https://eMATEConsulting.com/ (the “Site”) and the “Executive
                Toolkit” mobile applications (collectively, the “Service”). This
                Privacy Policy applies only to information collected by “eMATE”
                from users of our Services and visitors of our websites. Access
                to the Services is provided pursuant to the Terms of Service.
                Capitalized terms used but not defined in this Privacy Policy,
                shall have the meaning set forth in the Terms of Service. Please
                read the following carefully. The Terms of Service defines how
                data will be collected, used, and managed.
              </p>
              <h3>Terms of Service</h3>
              <p>
                eMATE” has constructed the “Executive Toolkit” to consume base
                company information and responses on basic business questions to
                formulate high level strategic insight. The data contained in
                the traversal of a solution is maintained in history only for
                scenario review. This data is not harvested or reviewed by eMATE
                unless requested. Set-up company information is requested when
                initializing the “Executive Toolkit” solution to collect
                standard data that all solutions will reference in solutioning.
                This is data is stored in a secure cloud location for your use
                only and is referenced by your solutions when required. No
                unauthorized use of this data is completed without your consent.
              </p>
              <h3>Discontinuation of Services.</h3>
              <p>
                If the Client chooses to discontinue the use of the Services
                provided, the user proprietary data is removed within a
                reasonable amount of time for security reasons. Once cleaned,
                the User may choose to re-engage the solution but will need to
                repopulate the environment as though they are a new user.
              </p>
              {/* <h3>
                Morbi nunc odio, gravida at, cursus nec, luctus a, lorem.
                Maecenas tristique orci ac sem.
              </h3>
              <p>
                Duis ultricies pharetra magna. Donec accumsan malesuada orci.
                Donec sit amet eros. Lorem ipsum dolor sit amet, consectetuer
                adipiscing elit. Mauris fermentum dictum magna. Sed laoreet
                aliquam leo. Ut tellus dolor, dapibus eget, elementum vel,
                cursus eleifend, elit. Aenean auctor wisi et urna. Aliquam erat
                volutpat. Duis ac turpis. Integer rutrum ante eu lacus. Lorem
                ipsum dolor sit amet, consectetuer adipiscing elit.{" "}
              </p>
              <h3>
                Ut tellus dolor, dapibus eget, elementum vel, cursus eleifend,
                elit. Aenean auctor wisi et urna.
              </h3>
              <p>
                Aliquam erat volutpat. Duis ac turpis. Integer rutrum ante eu
                lacus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Praesent vestibulum molestie lacus. Aenean nonummy hendrerit
                mauris. Phasellus porta. Fusce suscipit varius mi. Cum sociis
                natoque penatibus et magnis dis parturient montes, nascetur
                ridiculus mus. Nulla dui. Fusce feugiat malesuada odio. Morbi
                nunc odio, gravida at, cursus nec, luctus a, lorem. Maecenas
                tristique orci ac sem.
              </p> */}
            </div>
          </div>
        </section>
      </body>
    </html>
  );
};
