import React from "react";
import { Modal } from "react-bootstrap";
import "./ThankyouDialog.scss";

export function ThankyouDialog({ handleClose }) {
  return (
    <Modal
      size="md"
      className="thankyou__modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
    >
      <Modal.Body>
        <div className="mb-4 p-0 text-right">
          <button className="modal__crossicon" onClick={handleClose}></button>
        </div>
        <div className="mb-0 p-0 mb-0 p-0 text-center">
          <div className="statusicon success"></div>
          <h3 className="font-weight-bolder text-dark">Thank you!</h3>
          <div className="text__des">Your process is completed.</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
