import React from "react";
import { EditState } from "../../../../app/pages/EditState";
import UsaMapChart from "../UsaMapChart";
import { ArrowBackIos } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

function StepOne({
  state = [],
  organization = {},
  onFieldChange = () => {},
  onStateChange = () => {},
  manualSelectedStates,
  setManualSelectedStates,
}) {
  const handleFieldChange = (id, e) => {
    if (id === "overAllRevenue") {
      const value = Number(e.target.value.replaceAll(",", ""));
      if (value.toString() !== "NaN") {
        onFieldChange(id, value);
      }
    } else if (
      [
        "traditionalRetailRevenuePercentage",
        "eCommerceRevenuePercentage",
      ].indexOf(id) > -1
    ) {
      const value = Number(e.target.value);
      if (value.toString() !== "NaN" && value > -1 && value < 101) {
        const combinedAverageFields = {
          traditionalRetailRevenuePercentage:
            id === "traditionalRetailRevenuePercentage" ? value : 100 - value,
          eCommerceRevenuePercentage:
            id === "eCommerceRevenuePercentage" ? value : 100 - value,
        };
        onFieldChange(combinedAverageFields);
      }
    } else {
      onFieldChange(id, e.target.value);
    }
  };

  const prefix = "$ ";
  const history = useHistory();

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object

        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center" }}
        className="card-header border-0 py-8 custom-header"
      >
        <IconButton
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={history.goBack}
          aria-label="detail"
          style={{
            marginLeft: 3,
            marginTop: 7,
            marginRight: 10,
            marginBottom: 26,
            height: 34,
            width: 34,
            borderRadius: 5,
            color: "#3699FF",
          }}
        >
          <ArrowBackIos className="back" />
        </IconButton>
        <h3 style={{ marginBottom: 18 }} className="card-title flex-column ">
          <span className="card-label font-weight-bolder text-dark">
            Organization Configuration
          </span>
        </h3>
      </div>
      <div className="card-body pt-0">
        <form>
          <div className="general__info__block">
            <h4 className="card-title flex-column mb-4">
              <span className="card-label font-weight-bolder text-dark">
                General Information
              </span>
            </h4>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label text-dark-50 font-weight-bolder">
                    Company Name
                  </label>
                  <input
                    placeholder="Enter Company Name Here..."
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="text"
                    value={organization?.companyName}
                    onChange={handleFieldChange.bind(this, "companyName")}
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label text-dark-50 font-weight-bolder">
                    Upload logo
                  </label>
                  {/* <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="toolbox__uploadfile"
                >
                  {organization.iconLogo && (
                    <div className="uploadimg">
                      <img src={organization.iconLogo} alt="uploadimg" />
                      </div>
                  )}
                  <input
                    id="actual-btn"
                    accept="image/png, image/jpeg"
                    name="iconLogo"
                    type="file"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    onChange={(event) => {
                      getBase64(event.currentTarget.files[0]).then((result) => {
                        onFieldChange("iconLogo", result);
                      });
                    }}
                  /> */}
                  {/* <span
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    id="file-chosen"
                  >
                    No file chosen
                  </span> */}
                  {/* <span className="file__browse" for="actual-btn">
                    Browse
                  </span> */}
                  {/* </div> */}
                  <div className="toolbox__uploadfile">
                    {/* <span className="uploadicon"></span> */}
                    <div className="uploadimg">
                      <img src={organization.iconLogo} alt="uploadimg" />
                    </div>
                    <div
                      style={{
                        height: 55,
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                      className={`form-control form-control-solid`}
                    >
                      <label
                        style={{
                          backgroundColor: "#E6E6E6",
                          height: 37,
                          fontWeight: 600,
                          color: "#808080eb",
                        }}
                        for="files"
                        class="btn"
                      >
                        Browse Image
                      </label>
                      <input
                        id="files"
                        style={{ visibility: "hidden" }}
                        accept="image/png, image/jpeg"
                        name="icon_logo"
                        type="file"
                        onChange={(event) => {
                          getBase64(event.currentTarget.files[0]).then(
                            (result) => {
                              onFieldChange("iconLogo", result);
                            }
                          );
                        }}
                      />
                    </div>
                    {/* <label className="mb-0 w-100" htmlFor="actual-btn">
                    <input
                      className={`form-control form-control-solid h-auto py-5 px-6`}
                      id="actual-btn"
                      accept="image/png, image/jpeg"
                      name="icon_logo"
                      type="file"
                      onChange={(event) => {
                        getBase64(event.currentTarget.files[0]).then(
                          (result) => {
                            onFieldChange("iconLogo", result);
                          }
                        );
                      }}
                    />
                    <span className="file__browse" htmlFor="actual-btn">
                      Browse
                    </span>
                  </label> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="revenue_info__block">
            <h4 className="card-title flex-column mb-4">
              <span className="card-label font-weight-bolder text-dark">
                Revenue Information
              </span>
            </h4>
            <div className="row">
              <div className="col-md-12 col-lg-4">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label text-dark-50 font-weight-bolder">
                    Overall Revenue($)
                  </label>
                  <CurrencyInput
                    // prefix={prefix}
                    name="overAllRevenue"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    value={organization?.overAllRevenue}
                    placeholder="Enter Annual Revenue Here..."
                    onChange={handleFieldChange.bind(this, "overAllRevenue")}
                    // onBlur={handleOnBlur}
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="form-control form-control-solid h-auto py-5 px-6"
                  />
                  {/* <input
                  placeholder="Enter Annual Revenue Here..."
                  type="number"
                  className={`form-control form-control-solid h-auto py-5 px-6`}
                  name="text"
                  value={organization?.overAllRevenue}
                  onChange={handleFieldChange.bind(this, "overAllRevenue")}
                /> */}
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label text-dark-50 font-weight-bolder">
                    E-Commerce Revenue (%)
                  </label>
                  <input
                    placeholder="Enter Annual Units Shipped Here..."
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="text"
                    value={organization?.eCommerceRevenuePercentage}
                    onChange={handleFieldChange.bind(
                      this,
                      "eCommerceRevenuePercentage"
                    )}
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label text-dark-50 font-weight-bolder">
                    Traditional Retail Revenue (%)
                  </label>
                  <input
                    placeholder="Enter eCommerce % Here..."
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="text"
                    value={organization?.traditionalRetailRevenuePercentage}
                    onChange={handleFieldChange.bind(
                      this,
                      "traditionalRetailRevenuePercentage"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="unitper_order__block">
            <h4 className="card-title flex-column mb-4">
              <span className="card-label font-weight-bolder text-dark">
                Unit per order
              </span>
            </h4>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label text-dark-50 font-weight-bolder">
                    E-Commerce
                  </label>
                  <input
                    placeholder="Enter Units/Order Here..."
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="text"
                    value={organization?.eCommerceUnit}
                    onChange={handleFieldChange.bind(this, "eCommerceUnit")}
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label text-dark-50 font-weight-bolder">
                    Traditional Retail
                  </label>
                  <input
                    placeholder="Enter Units/Order Here..."
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="text"
                    value={organization?.traditionalRetailUnit}
                    onChange={handleFieldChange.bind(
                      this,
                      "traditionalRetailUnit"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="select__state__block">
          <h3 className="card-title flex-column">
            <span className="card-label font-weight-bolder text-dark">
              States Where Products Sold
            </span>
          </h3>
          {/* <div className="form-group">
            <div className="checkbox-inline">
              <label className="checkbox">
                <input type="checkbox" name="all" />
                <span></span>All
              </label>
              <label className="checkbox">
                <input type="checkbox" name="northeast" />
                <span></span>Northeast
              </label>
              <label className="checkbox">
                <input type="checkbox" name="southeast" />
                <span></span>Southeast
              </label>
              <label className="checkbox">
                <input type="checkbox" name="midwest" />
                <span></span>Midwest
              </label>
              <label className="checkbox">
                <input type="checkbox" name="west" />
                <span></span>West
              </label>
            </div>
          </div>
          <div className="usa__map">
            <UsaMapChart />
          </div> */}
          <EditState
            state={state}
            onStateChange={onStateChange}
            manualSelectedStates={manualSelectedStates}
            setManualSelectedStates={setManualSelectedStates}
          />
        </div>
      </div>
    </>
  );
}

export default StepOne;
