import React from "react";
import {  ToolboxDetails, ToolboxLanding, ToolboxCategory, ToolboxSubCategory } from "../../_metronic/_partials";

export function ToolboxDetailsPage(props) {
  return <div>
    {/* <ToolboxDetails/>
    <ToolboxLanding/>
    <ToolboxCategory /> */}
    <ToolboxSubCategory/>
  </div>;
}
