import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./ToolboxLanding.scss";
import { getOrganizationsList } from "../../../app/modules/Organizations/_redux/organizationsSelectors";
import { actions as Organizations } from "../../../app/modules/Organizations/_redux/organizationsRedux";

export function ToolboxLanding() {
  const organization = useSelector(getOrganizationsList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (organization.length == 0) {
      dispatch(Organizations.getOrganizations());
    }
  }, []);
  const history = useHistory();

  const handleChange = () => {
    history.push("/toolbox");
  };

  return (
    <div className="ToolboxLanding__main">
      <div onClick={handleChange} className="ToolboxLanding__fullbg"></div>
      <div className="Toolbox__top__contant">
        <h3>Welcome to the Executive Toolkit .....</h3>
        <p>
          A Solution Created by Executives for Executives Moving at the Speed of
          Digital
        </p>
      </div>
      <div className="posifixed__block">
        <div className="comp-logo">
          <img alt="logoicon" src={organization?.iconLogo} />
        </div>
        <div className="enter__imgfixed">
          <img
            onClick={handleChange}
            alt="enterimg"
            src="/media/misc/enter-img.png"
            style={{ height: "230px" }}
          />
        </div>
      </div>
      <div className="Toolbox__bottom__contant">
        <div className="pow-logo">
          <img alt="powemate" src="/media/logos/pow-emate.png" />
        </div>
      </div>
      <div className="Toolbox__bottom__contant information">
        <p>
          For More Information Concerning eMate Consulting, LLC Visit us at{" "}
          <a target="_blank" href="https://www.eMateConsulting.com">
            www.eMateConsulting.com
          </a>
        </p>
      </div>
    </div>
  );
}
