import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { login } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UpdateToken: "[Update Token] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  IsLoading: "[is Loading Auth] Action",
  Error: "[Error Auth] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: "",
  isLoading: false,
  error: "",
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.UpdateToken: {
        const { authToken } = action.payload;

        return { ...state, authToken };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.IsLoading: {
        const { isLoading } = action.payload;
        return { ...state, isLoading };
      }

      case actionTypes.Error: {
        const { error } = action.payload;
        return { ...state, error };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (email, password) => ({
    type: actionTypes.Login,
    payload: { email, password },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  updateToken: (authToken) => ({
    type: actionTypes.UpdateToken,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  isLoading: (isLoading) => ({
    type: actionTypes.IsLoading,
    payload: { isLoading },
  }),
  error: (error) => ({
    type: actionTypes.Error,
    payload: { error },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga(action) {
    const { email, password } = action.payload;
    yield put(actions.isLoading(true));
    try {
      const response = yield login(email, password);
      const user = response.data.data;
      const token = response.data.data.token;
      if (user?.userRole.indexOf("executive") > -1) {
        if (user || token) {
          yield put(actions.updateToken(token));
          yield put(actions.fulfillUser(user));
        } else {
          yield put(actions.error("Login credentials are invalid!"));
        }
      } else {
        yield put(actions.error("User role is not valid for this site!"));
      }
    } catch (error) {
      let message = error?.message;
      if (error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }
      yield put(actions.error(message || "Login credentials are invalid!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    //yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    //const { data: user } = yield getUserByToken();
    //yield put(actions.fulfillUser(user));
  });
}
