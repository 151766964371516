import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import RingLoader from "react-spinners/RingLoader";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import SubCategorySlider from "./SubCategorySlider";
import "./ToolboxSubCategory.scss";
import { actions as Questions } from "../../../app/modules/Question/_redux/questionsRedux";
import { actions as WEB_TRAVERS } from "../../../app/modules/WebTravers/_redux/webTraversRedux";
import {
  getSubtopicDetails,
  getQuestionLoader,
} from "../../../app/modules/Question/_redux/questionsSelectors";
import HelpIcon from "../Help/HelpIcon";
export function ToolboxSolutionPage() {
  const dispatch = useDispatch();
  const { subToolBoxId } = useParams();
  const subtopicDetails = useSelector(getSubtopicDetails);
  const isLoading = useSelector(getQuestionLoader);

  useEffect(() => {
    dispatch(Questions.getSubtopicDetails(subToolBoxId));
    dispatch(WEB_TRAVERS.resetToInitialState());
  }, []);
  return (
    <>
      {isLoading && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      {!isLoading && (
        <div className="ToolboxSubCategory__main SubCategory__fullbg">
          <div className="Toolbox__top__contant">
            <div className="leftcol">
              <h2>
                <Link to={"/toolbox"}>
                  <span className="backarrow"></span>
                </Link>
                {subtopicDetails?.name}
              </h2>

              <p>
                {subtopicDetails?.tagLine != "" &&
                subtopicDetails?.tagLine != undefined
                  ? subtopicDetails?.tagLine
                  : "Assessment Tools to Evaluate the Changes in Operational Tactics"}
              </p>
            </div>
            <div className="rightcol__logo">
              <div className="pow-logo">
                <img alt="powemate" src="/media/logos/pow-emate.png" />
              </div>
            </div>
          </div>
          <div className="SubCategory__middle__block">
            <div className="SubCategory__contant__text">
              <h3>
                {subtopicDetails?.question != "" &&
                subtopicDetails?.question != undefined
                  ? subtopicDetails?.question
                  : "What Happens if? ....."}
              </h3>
              <p>
                {subtopicDetails?.solution != "" &&
                subtopicDetails?.solution != undefined
                  ? subtopicDetails?.solution
                  : "Evaluating changes in operating strategy help align Doing with Customer Satisfaction"}
              </p>
            </div>
            <div
              className={`illustration__img border${subtopicDetails?.questions?.length}`}
            >
              <img
                alt="illustration"
                src={
                  subtopicDetails?.tool_image != "" &&
                  subtopicDetails?.tool_image != undefined
                    ? subtopicDetails?.tool_image
                    : "/media/bg/sub-cat-illustration.png"
                }
              />
            </div>
          </div>
          <div className="Toolbox__bottom__Slider">
            <SubCategorySlider
              name={subtopicDetails?.name}
              questions={subtopicDetails?.questions}
            />
          </div>
          <div className="bottom-fixed">
            <div className="bottom__tagline">
              Solution Selection Bar.... Choose the Solution of Interest
            </div>
            <div className="bottom-imgline">
              <img
                alt="bottom-img"
                src="/media/misc/subcategory-bottomfix.png"
              />
            </div>
          </div>
        </div>
      )}
      <HelpIcon pageName={"UserToolBoxSolutionSection"} />
    </>
  );
}
