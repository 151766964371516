import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import "../header.css";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item ${getMenuItemActive("/dashboard")}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/organization")}`}>
          <NavLink className="menu-link" to="/organization">
            <span className="menu-text">Organization</span>
          </NavLink>
        </li>
        {/* <li className={`menu-item ${getMenuItemActive("/toolbox")}`}>
          <NavLink className="menu-link" to="/toolbox">
            <span className="menu-text">Toolbox</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive("/history")}`}
        >
          <NavLink className="menu-link" to="/history">
            <span className="menu-text">History</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
